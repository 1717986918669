import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1.5rem 20px;

  span {
    margin-left: 10px;
    color: ${theme.colors.black};
    font-size: 18px;
    line-height: 15px;
    font-weight: 500;
    cursor: pointer;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  @media (max-width: 600px) {
    height: 7vh;
  }
`
