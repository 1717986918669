import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
  Div,
  InputContainer
} from '../../styles'
import { maskCNPJ, maskPhone } from '../../../../utils/masks'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toast } from 'react-toastify'

interface ProspectionFormProps {
  submit: boolean
  lead: any
  disable?: boolean
  handleFormData: any
}

const schemaProspection = yup.object().shape({
  social_reason: yup.string().trim().required('Ocorreu um erro, verifique se os dados preenchidos estão corretos e tente novamente!'),
  contact_rh: yup.string().trim().required().typeError('Ocorreu um erro, verifique se os dados preenchidos estão corretos e tente novamente!'),
  phone: yup.string().trim().required('Ocorreu um erro, verifique se os dados preenchidos estão corretos e tente novamente!'),
  cnpj: yup.string().trim().length(18).required(),
  function: yup.string().trim().required(),
  email: yup.string().email().trim().required()
}).required()

const ProspectionForm: React.FC<ProspectionFormProps> = (props: ProspectionFormProps) => {
  const formElement = useRef<HTMLFormElement | null>(null)

  const { register, handleSubmit, setValue, formState: { isDirty } } = useForm({
    defaultValues: {
      social_reason: props.lead.steps.find((step: any) => step.name === 'Prospecção').data.social_reason ?? '',
      contact_rh: props.lead.steps.find((step: any) => step.name === 'Prospecção').data.contact.first_name ?? '',
      phone: maskPhone(props.lead.steps.find((step: any) => step.name === 'Prospecção').data.contact.phone) ?? '',
      cnpj: maskCNPJ(props.lead.steps.find((step: any) => step.name === 'Prospecção').data.cnpj) ?? '',
      function: props.lead.steps.find((step: any) => step.name === 'Prospecção').data.contact.professional_role ?? '',
      email: props.lead.steps.find((step: any) => step.name === 'Prospecção').data.contact.email ?? ''
    },
    resolver: yupResolver(schemaProspection)
  })

  const onSubmit = async (data: any, e: any): Promise<void> => {
    e.preventDefault()
    if (!(await schemaProspection.isValid(data))) {
      toast.error('Ocorreu um erro, verifique se os dados preenchidos estão corretos e tente novamente!')
      return
    }

    if (!isDirty) return

    const [firstName, ...lastName] = data.contact_rh.split(' ')

    const prospectionData = {
      social_reason: data.social_reason,
      cnpj: maskCNPJ(data.cnpj),
      contact: {
        first_name: firstName,
        last_name: lastName.join(' '),
        phone: data.phone,
        professional_role: data.function,
        email: data.email
      }
    }

    const steps = props.lead?.steps as any[]

    const payload = {
      step_sequence: '0',
      step_sequence_id: steps.find(el => el?.sequence === parseInt('0'))?.id,
      data: prospectionData
    }

    props.handleFormData(payload)
  }

  useEffect(() => {
    if (props.submit && formElement.current) formElement.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }, [props.submit])

  return (
    <form ref={formElement} onSubmit={handleSubmit(onSubmit)}>
      <Div>
        <InputContainer>
          <label>Razão Social</label>
          <input
            disabled={props.disable}
            type='text'
            {...register('social_reason')}
            name='social_reason'
          />
        </InputContainer>
        <InputContainer>
          <label>Contato RH</label>
          <input
            disabled={props.disable}
            type='text'
            {...register('contact_rh')}
            name='contact_rh'
          />
        </InputContainer>
        <InputContainer>
          <label>Telefone</label>
          <input
            disabled={props.disable}
            type='text'
            {...register('phone')}
            name='phone'
            onChange={(e) => { setValue('phone', maskPhone(e.target.value)) }}
          />
        </InputContainer>
      </Div>
      <Div>
      <InputContainer>
          <label>CNPJ</label>
          <input
            disabled={props.disable}
            type='text'
            {...register('cnpj')}
            name='cnpj'
            onChange={(e) => { setValue('cnpj', maskCNPJ(e.target.value)) }}
          />
        </InputContainer>
        <InputContainer>
          <label>Função</label>
          <input
            disabled={props.disable}
            type='text'
            {...register('function')}
            name='function'
          />
        </InputContainer>
        <InputContainer>
          <label>Email</label>
          <input
            disabled={props.disable}
            type='text'
            {...register('email')}
            name='email'
          />
        </InputContainer>
      </Div>
    </form>
  )
}

export default ProspectionForm
