import api from '../../services/api'
import { toast } from 'react-toastify'
import { useLocation, useNavigate } from 'react-router-dom'
import { BackLink } from '../../components'
import React, { useEffect, useState } from 'react'
import {
  Container,
  Step,
  Title,
  Box,
  Header,
  Name,
  ButtonContainer,
  Button
} from './styles'
import ProspectionForm from './components/ProspectionForm'
import TriageForm from './components/TriageForm'
import LeadForm from './components/LeadForm'
import ScheduleForm from './components/Schedule'

const IndicationsDetails: React.FC = () => {
  const navigate = useNavigate()
  const { state: { data: leadHistory } }: any = useLocation()
  const [lead, setLead] = useState<any>(leadHistory)
  const [, setAllowToQualify] = useState(false)
  const [loading, setLoading] = useState(false)

  const [disable, setDisable] = useState(true)
  const [disableSaveButton, setDisableSaveButton] = useState(true)
  const [submit, setSubmit] = useState(false)

  const [prospectionResponse, setProspectionResponse] = useState<any>({})
  const [triageResponse, setTriageResponse] = useState<any>({})
  const [leadResponse, setLeadResponse] = useState<any>({})
  const [, setScheduleResponse] = useState<any>({})

  const haveSchedule = !!lead.data.schedule

  useEffect(() => {
    setSubmit(false)
    setDisableSaveButton(true)

    if (Object.keys(prospectionResponse).length !== 0 && Object.keys(triageResponse).length !== 0 && Object.keys(leadResponse).length !== 0) {
      setLoading(true)
      updateIndication(prospectionResponse).then(() => {
        updateIndication(triageResponse).then(() => {
          updateIndication(leadResponse).then(() => {
            setDisableSaveButton(false)
            setLoading(false)
            qualifyLead()
          })
        })
      })
    }

    // Caso seja alterado apenas os campos de prospecção
    if (Object.keys(prospectionResponse).length !== 0) {
      setLoading(true)
      setDisableSaveButton(true)
      updateIndication(prospectionResponse).then(() => setLoading(false))
    }

    // Cado triagem esteja preenchida
    if (Object.keys(triageResponse).length !== 0) {
      setLoading(true)
      setDisableSaveButton(true)
      updateIndication(triageResponse, true)
      if (Object.keys(leadResponse).length !== 0) {
        setLoading(true)
        updateIndication(leadResponse).then(() => {
          qualifyLead()
        })
        return
      } else {
        setDisableSaveButton(false)
        setLoading(false)
      }
    }

    // Caso o lead esteja preenchido e o triage não
    if (Object.keys(leadResponse).length !== 0 && Object.keys(triageResponse).length === 0) {
      const triage = leadHistory.steps.find((step: any) => step.name === 'Triagem')

      if (!triage.data.marked) {
        setLoading(false)
        setDisableSaveButton(false)
        toast.error('Preencha a triagem para continuar!')
        return
      }

      setLoading(true)
      updateIndication(leadResponse).then(() => {
        qualifyLead()
      })
    }
  }, [prospectionResponse, triageResponse, leadResponse])

  const isAllStepsMarked = (stepsUpdated?: any): void => {
    const steps = Array.isArray(stepsUpdated) && stepsUpdated.length > 0 ? stepsUpdated : lead?.steps
    const markedSteps = (steps || []).filter((el: any) => el?.data?.marked === true) as []
    setAllowToQualify(markedSteps.length > 2 && markedSteps.length < 4)
  }

  const updateIndication = async (payload: any, disable: boolean = false): Promise<void> => {
    await api.put(`/indications/${String(lead?.id)}`, payload).then((response: { data: any }) => {
      isAllStepsMarked(response.data[0]?.steps)
      setLead(response.data[0])
      if (disable) {
        setDisableSaveButton(true)
      } else {
        setDisableSaveButton(false)
      }
      setDisableSaveButton(true)
      return toast.success('Alteração feita com sucesso!')
    }).catch(error => {
      setDisableSaveButton(false)
      if (error.response.status === 400) {
        return toast.error(
          'Ocorreu um erro, verifique se os dados preenchidos estão corretos e tente novamente!'
        )
      }

      return toast.error(
        'Ocorreu um erro desconhecido ao criar a indicação, tente novamente em alguns momentos!'
      )
    })
  }

  const qualifyLead = async (): Promise<void> => {
    await api.patch(`/indications/${String(lead?.id)}/qualify`, null).then(() => {
      toast.success('Indicação qualificada com sucesso!')
      setLoading(true)
      setDisableSaveButton(true)
      setTimeout(() => {
        navigate('/indications')
      }, 500)
    }).catch(() => {
      toast.error('Erro ao qualificar indicação!')
    })
  }

  useEffect(() => {
    if (lead?.id) {
      isAllStepsMarked()
    }
  }, [])

  return (
    <Container>
      <BackLink link='/indications' />
      <Header>
        <div>
          <Name>Detalhes da indicação</Name>
        </div>
      </Header>
      <Step>
        <Title>1. Prospecção</Title>
        <Box>
          <ProspectionForm submit={submit} lead={lead} handleFormData={setProspectionResponse} disable={disable} />
        </Box>
      </Step>
      <Step>
        <Title>2. Triagem</Title>
        <Box>
          <TriageForm submit={submit} lead={lead} handleFormData={setTriageResponse} disable={disable} />
        </Box>
      </Step>
      <Step>
        <Title>3. Lead</Title>
        <LeadForm submit={submit} lead={lead} handleFormData={setLeadResponse} disable={disable} />
      </Step>
      {
        haveSchedule && (
          <Step>
            <Title>4. Agendamento</Title>
            <ScheduleForm submit={submit} lead={lead} handleFormData={setScheduleResponse} disable={disable} />
          </Step>
        )
      }
      <ButtonContainer flex>
        <Button disabled={lead.status === 'qualified'} backgroundColor='#FFC600' type='button' onClick={() => { setDisableSaveButton(false); setDisable(false) }}>
          Editar
        </Button>

        <Button disabled={lead.status === 'qualified' || disableSaveButton} type='button' onClick={() => setSubmit(!submit)}>
          {loading ? 'Salvando...' : 'Salvar'}
        </Button>
      </ButtonContainer>
    </Container>
  )
}

export default IndicationsDetails
