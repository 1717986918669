import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Container = styled.div`
  position: absolute;
  z-index: 1040;

  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.2);

`
export const MessageWrapper = styled.div`

  position: fixed;
  bottom: 15px;
  right: 15px;
  height: 65vh;
  width: 385px;

  background: ${theme.colors.white};

  box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.25);
  border-radius: 15px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 22px;

`

export const MessageTitle = styled.div`
  display: flex;
  width: 100%;
  height: 49px;
  justify-content: space-between;
  
  background: ${theme.colors.primary};
  border-radius: 15px 15px 0px 0px;
  
  div{
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: center;

      p {
      margin-left: 15px;
      font-size: 18px;
      color: ${theme.colors.white};
      font-weight: 600;
    }

    img{
      margin-left: 10px;
      max-width: 24px;
      max-height: 24px;
    }
  }

  button{
    background: transparent;
    border: none;
    margin-right: 25px;
  }



`
export const MessageName = styled.div`
  width: 100;

  p{ 
    font-size: 14px;
    color: ${theme.colors.white};
    opacity: 0.6;
    font-weight: 800;
  }
`

export const MessageChat = styled.div`
  background-color: ${theme.colors.white};
  color: ${theme.colors.black};
  border-radius: 15px;
  height: 70%;
  width: 87%;
  padding: 15px;

  overflow-y: auto;

  #messages {
    display: flex;
    flex-direction: column;

    .message {
      max-width: 400px;
      min-width: 200px;
      border-radius: 7px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, .15);
      margin: 6px;
      padding: 1rem;
      color: black;
      font-weight: 400;
      align-items: center;
      word-break: break-word;
      background-color: ${theme.colors.background};
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 15px;

      span {
        display: inline;
        margin-bottom: 15px;
        text-overflow: ellipsis;
        word-wrap: break-word;
        font-size: 1rem;

      }

      strong {
        font-size: .75rem;
        font-weight: 600;
        text-transform: capitalize;
      }
    }

    .operator {
      text-align: left;
      align-self: flex-start;
    }

    .customer {
      text-align: right;
      align-self: flex-end;
      align-items: flex-end;
    }

  }
`
export const MessageInputs = styled.form`
  background-color: #EAEAEA;
  border-radius: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;

  input, select{
    background: transparent;
    border: none;
    color: ${theme.colors.black};
    width: 100%;
    font-size: 14px;
    white-space: pre-wrap;
    overflow-wrap: break-word;

    &:focus{
      outline: none;
    }
  }

  .input-field{
    display: flex;
    justify-content: space-between;
    margin: 18px 12px;
    background: #FFFFFF;
    border: 2px solid #BFBFBF;
    box-sizing: border-box;
    border-radius: 15px;  
    padding-left: 15px;
    color: ${theme.colors.black};
    width: 100%;

    ::placeholder{
      font-size: 14px;
      color: ${theme.colors.black};
      opacity: 0.8;
    }

  }
  img{
      margin: 10px 10px;
      height: 25px;
      width: 25px;
  }

  button{
    color: #FFC700; 
    position: absolute;
    align-self: center;
    padding: 15px;
    background: transparent;
    border: none;
    right: 15px;
  }

  
  @media (max-width: 768px){
    width: 100%;
  }

`
