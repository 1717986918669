import { AxiosRequestHeaders } from 'axios'
import React, { createContext, ReactNode, useEffect, useState } from 'react'
import api from '../services/api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

export interface SingInCredentials {
  email: string
  password: string
}
export interface SingInCredentialsByBlueone {
  contactId: string
}

export type AxiosHeadersProps = AxiosRequestHeaders & {
  Authorization: string
}

interface IUser {
  email: string
  blueone_contact_id: string
  agreed_at: string | null
  verified_at: string | null
  id: string | undefined
}

interface AuthContextData {
  signIn: (credentials: SingInCredentials) => Promise<void>
  signInByBlueone: (credentials: SingInCredentialsByBlueone) => Promise<void>
  logout: () => Promise<void>
  isAuthenticated: boolean
  user?: IUser
  contact?: any
  recoverUser?: any
}

interface AuthProviderProps {
  children: ReactNode
}

export const AuthContext = createContext({} as AuthContextData)

const STORAGE_USER_KEY = '@portalvr/user'
const STORAGE_TOKEN_KEY = '@portalvr/token'
const STORAGE_CONTACT_KEY = '@portalvr/contact'
const STORAGE_FORM_KEY = '@portalvr/form'
const STORAGE_FORM_SALES_KEY = '@portalvr/form/sales'
const STORAGE_FORM_INDICATIONS_KEY = '@portalvr/form/indications'

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const navigate = useNavigate()
  const [user, setUser] = useState<IUser>()
  const [contact, setContact] = useState<any>()
  useEffect(() => {
    recoverUser()
  }, [])

  const isAuthenticated = !!user

  async function signIn ({ email, password }: SingInCredentials): Promise<void> {
    try {
      const response = await api.post('/auth/login', {
        email,
        password
      })

      const { token, ...rest } = response.data

      const contactId = String(rest.blueone_contact_id)
      const contactResponse = await api.get(`/users/${contactId}/contact`)

      localStorage.setItem(STORAGE_TOKEN_KEY, token)
      localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(rest))
      localStorage.setItem(STORAGE_CONTACT_KEY, JSON.stringify(contactResponse.data.data))

      setUser(rest)
      setContact(contactResponse.data?.data)
    } catch (error: any) {
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error)
      } else {
        toast.error('Ops! Tente novamente mais tarde')
      }
    }
  }

  async function signInByBlueone ({ contactId }: SingInCredentialsByBlueone): Promise<void> {
    try {
      const { data } = await api.post('/auth/login-as/blueone', {
        contactId
      })

      const { token, ...rest } = data

      const contactIdResponse = String(rest.blueone_contact_id)
      const contactResponse = await api.get(`/users/${contactIdResponse}/contact`)

      localStorage.setItem(STORAGE_TOKEN_KEY, token)
      localStorage.setItem(STORAGE_USER_KEY, JSON.stringify(rest))
      localStorage.setItem(STORAGE_CONTACT_KEY, JSON.stringify(contactResponse.data.data))

      setUser(rest)
      setContact(contactResponse.data?.data)
      navigate('/indications', { replace: true })
    } catch (error: any) {
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error)
        await logout()
      } else {
        toast.error('Ops! Tente novamente mais tarde')
        await logout()
      }

      setTimeout((): void => {
        navigate('/', { replace: true })
      }, 500)
    }
  }

  async function recoverUser (): Promise<void> {
    try {
      const recoveredUser = await localStorage.getItem(STORAGE_USER_KEY)

      if (recoveredUser && recoveredUser !== 'undefined') {
        const newUser: IUser = JSON.parse(recoveredUser)
        setUser(newUser)
        if (newUser) {
          const contactResponse = await api.get(`/users/${newUser?.blueone_contact_id}/contact`)
          localStorage.setItem(STORAGE_CONTACT_KEY, JSON.stringify(contactResponse.data.data))
          setContact(contactResponse.data.data)
        }
      } else {
        await logout()
      }
    } catch (error: any) {
      await logout()
    }
  }

  async function logout (): Promise<void> {
    await localStorage.removeItem(STORAGE_TOKEN_KEY)
    await localStorage.removeItem(STORAGE_USER_KEY)
    await localStorage.removeItem(STORAGE_CONTACT_KEY)
    await localStorage.removeItem(STORAGE_FORM_KEY)
    await localStorage.removeItem(STORAGE_FORM_SALES_KEY)
    await localStorage.removeItem(STORAGE_FORM_INDICATIONS_KEY)
    setUser(undefined)
  }

  return (
    <AuthContext.Provider value={{ signIn, signInByBlueone, isAuthenticated, user, contact, logout, recoverUser }}>
      {children}
    </AuthContext.Provider>
  )
}
