import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Container = styled.button`
  display: flex;
  background-color: ${theme.colors.primary};
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 250px;
  border-radius: 22px;
  padding: 10px 20px;
  color: ${theme.colors.white};
  border: none;
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 40px;
  cursor: pointer;

  
  @media (max-width: 425px){
    display: flex;
    position: absolute;
    left: 40px;
    z-index: 0;
  }

  .botao{
    display: flex;
  } 
  
  &:before{
    content: '';
    border-radius: 22px;
    bottom: 0px;
    left: 0px;
    position: absolute;
    width: 0;
    height: 60px;
    background-color: ${theme.colors.yellow};
    transition: 1s;

  }

  &:hover:before{
    width: 100%;
    color: ${theme.colors.black};
    z-index: 1;
  }
  .messages--Box {
    margin-left: 1.5rem;

    p {
      color: ${theme.colors.white};
      font-weight: 600;
      font-size: 1rem;
    }

    .chat-message {
      font-size: 1.25rem;
    }
  }

  img{
    max-width: 36px;
    max-height: 36px;
  }

.botao{
  z-index:5;
}


`
