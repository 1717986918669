import styled from 'styled-components'
import { theme } from '../../../../styles/theme'

export const CancelButtonIcon = styled.button`
  background-color: ${theme.colors.red};
  width: auto;
  height: auto;
  color: ${theme.colors.white};
  padding: 0.25rem 0.5rem;
  margin: auto 0.5rem;
  font-size: 12px;
  border: none;
  text-decoration: none;
  border-radius: 100%;

  &:disabled {
    opacity: 0.5;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 15px;
  }
`
