import axios from 'axios'
import { environment } from '../enviroment'

const token = localStorage.getItem('token')

const api = axios.create({
  baseURL: environment.apiUrl,
  headers: {
    Authorization: `Bearer ${String(token)}`
  }
})

export default api
