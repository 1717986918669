import React, { useContext } from 'react'
import { useParams } from 'react-router-dom'

import { AuthContext } from '../../context/AuthContext'
import { Container } from '../SingIn/styles'

const Redirect: React.FC = () => {
  const { signInByBlueone } = useContext(AuthContext)
  const { contactId } = useParams()

  async function loginByBlueone (contactId: string): Promise<void> {
    await signInByBlueone({ contactId })
  }

  if (contactId) {
    loginByBlueone(contactId)
  }

  return (
    <Container></Container>
  )
}

export default Redirect
