import styled, { css } from 'styled-components'
import { theme } from '../../styles/theme'

export const Container = styled.div`
  width: 80%;
  height: 80vh;
  overflow-y: auto;
  padding-right: 10px;
  
  @media (max-width: 768px) {
    height: 90vh;
  }
`

export const Step = styled.div`
  width: 100%;

  & + div {
    margin-top: 30px;
  }

`

export const Header = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  p{
    font-weight: 600;
    color: ${theme.colors.black};
  }
  
`
export const Name = styled.p`
  font-size: 18px;
  line-height: 22px;
`

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + div {
    margin-top: 30px;
  }

  div {
    & + div {
      margin-left: 10px;
    }
  }
  
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;

    & + div {
      margin-top: 0px;
    }
  }
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${theme.colors.black};
  margin-bottom: 15px;
`

interface PropsBox {
  lead?: boolean
}

export const Box = styled.div<PropsBox>`
  width: 100%;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.border};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px;

  ${({ lead }) => lead && css`
    background: ${theme.colors.primary};
    color: ${theme.colors.white}

    input, select {
      color: ${theme.colors.white};
    }

    label {
      color: ${theme.colors.white};
    }
  `}

  @media (max-width: 600px) {
      label{
        margin-top: 15px;
      }
      input{
        margin-bottom: 15px;
      }
  }

`

export const ButtonContainer = styled.div`
  width: 100%;
`

export const Button = styled.button`
  width: 20%;
  margin: 0 auto 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.primary};
  border-radius: 5px;
  color: ${theme.colors.white};
  border: 0;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;

  @media (max-width: 600px) {
    width: 80%;
    background-color: ${theme.colors.yellow}
  }

`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
    color: #212529;
    margin-bottom: 10px;
  }

  input {
    padding: 0 1.5rem;
    height: 3rem;
    width: 100%;
    border-radius: 10px;
    background: transparent;
    border: 1px solid ${theme.colors.border};

    font-weight: 400;
    font-size: 1.125rem;
  }

  select{
  outline: 0;
  box-shadow: none;
  background: transparent;
  margin: 0;
  text-indent: 0;
  font-size: 13px;
  color: ${theme.colors.selectIcon};
  border: 1px solid ${theme.colors.border};
  border-radius: 30px;
  width: 100%;
  padding: 0 60px 0 15px;
  height: 3rem;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }

  > option {
    color: ${theme.colors.selectIcon};
  }
  }
`

export const InputSelectSchedule = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 20%;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
    color: #212529;
    margin-bottom: 10px;
  }

  input {
    padding: 0 1.5rem;
    height: 3rem;
    width: 100%;
    border-radius: 10px;
    background: transparent;
    border: 1px solid ${theme.colors.border};

    font-weight: 400;
    font-size: 1.125rem;
  }

  select{
  outline: 0;
  box-shadow: none;
  background: transparent;
  margin: 0;
  text-indent: 0;
  font-size: 13px;
  color: ${theme.colors.selectIcon};
  border: 1px solid ${theme.colors.border};
  border-radius: 30px;
  width: 100%;
  padding: 0 60px 0 15px;
  height: 3rem;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }

  > option {
    color: ${theme.colors.selectIcon};
  }
  }
  @media only screen and (max-width: 425px) {

    width: 100%;

    select, input{
      width: 100%;
      margin-bottom: 0.5rem;
    }

    label {
      margin-left: 0.25rem;
    }
  }
`

export const TextAreaInput = styled.textarea`
  padding: 10px;
  height: 100px;
  width: 100%;
  line-height: 1.5 !important;
  border-radius: 5px !important;
  font-size: 1rem !important;
  border: 0.5px solid #000;
  padding: 5px;

  @media only screen and (max-width: 425px) {
    height: 200px;
  }

`

export const CheckBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 425px) {
    width: 50%;
    margin: 0 auto;
    padding-top: 0.5rem;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

export const CheckBoxContainer = styled.div`
  padding: 0.5rem 0.30rem 0;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: max-content;

  label {
    font-weight: 500;
    font-size: 12px;
    color: #212529;
    margin-left: 0.25rem;
  }

  input {
    height: 1rem;
    width: 1rem;
    background: transparent;
    border: 1px solid ${theme.colors.border};
  }

  @media only screen and (max-width: 768px) {
    label, input {  
      margin: 0;
    }

    label {
      margin-left: 0.25rem;
    }
  }
`

export const ButtonIcon = styled.button`
  background-color: ${theme.colors.primary};
  width: auto;
  height: auto;
  color: ${theme.colors.white};
  padding: 0.5rem;
  margin: 0.5rem;
  margin-bottom: 1rem;
  font-size: 12px;
  border: none;
  text-decoration: none;
  border-radius: 5px;

  &:disabled {
    opacity: 0.5;
  }
`
export const CancelButtonIcon = styled.button`
  background-color: ${theme.colors.red};
  width: auto;
  height: auto;
  color: ${theme.colors.white};
  padding: 0.25rem 0.5rem;
  margin: auto 0.5rem;
  font-size: 12px;
  border: none;
  text-decoration: none;
  border-radius: 100%;

  &:disabled {
    opacity: 0.5;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 15px;
  }
`
