import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IMAGES } from '../../../assets'
import { theme } from '../../../styles/theme'
import { maskCNPJ } from '../../../utils/masks'

import { Container, Div, Info, Label, Value, ButtonContainer, Button } from './styles'

const Card = ({ labels, indicationsData, sale }) => {
  const navigate = useNavigate()

  const formatDate = (date) => {
    const [onlyDate] = new Date(date).toLocaleString().split(',') // get only the date
    return onlyDate
  }

  return (
  <Container>
    <Div>
      <Info>
        <Label>{labels[0]}</Label>
        <Value>{ indicationsData?.data?.cnpj || (indicationsData?.data?.prospection?.cnpj.includes('-') ? indicationsData?.data?.prospection?.cnpj : maskCNPJ(indicationsData?.data?.prospection?.cnpj)) || ''}</Value>
      </Info>
      <Info>
        <Label>{labels[1]}</Label>
        <Value>{indicationsData?.step_name || 'Indicação enviada'}</Value>
      </Info>
      <Info>
        <Label>{labels[2]}</Label>
        <Value>{indicationsData?.data?.protocol || '-'}</Value>
      </Info>
    </Div>
    <Div>
      <Info>
        <Label>{labels[3]}</Label>
        <Value data>{formatDate(indicationsData?.updated_at) || '-'}</Value>
      </Info>
      <Info>
        <Label>
          {labels[4]}
          <Value>{indicationsData?.data?.status_gn || '-'}</Value>
        </Label>
      </Info>
      <ButtonContainer>
        <Button
          backgroundColor={sale ? theme.colors.primary : indicationsData?.status === 'qualified' ? theme.colors.primary : theme.colors.yellow}
          onClick={() => navigate(`${sale ? '/sale/details' : '/indications/more-details'}`, { state: { data: indicationsData } })}
        >
          Mais detalhes <img src={IMAGES.ARROW_RIGHT} />
        </Button>
      </ButtonContainer>
      </Div>
    </Container>
  )
}

export default Card
