import React from 'react'
import Card from './Card'
import { Container } from './styles'

interface PropsCards {
  labels: string[]
  moreDetailsLink?: string
  indicationsData?: any[]
  sale?: boolean
}

const Cards: React.FC<PropsCards> = ({ labels, indicationsData, sale }) => {
  const listIndicator: JSX.Element[] | undefined = indicationsData?.map((indication) => {
    return <Card key={indication?.id} labels={labels} indicationsData={indication} sale={sale}/>
  })

  return (
    <Container>
      {listIndicator}
    </Container>
  )
}

export default Cards
