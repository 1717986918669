import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const FormSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
    color: #212529;
    margin-bottom: 20px;
  }
`

export const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 38px;
  overflow: hidden;
  border-radius: 3px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    pointer-events: none;
    z-index: 1;
  }

  &::after {
    content: '';
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    border: solid ${theme.colors.selectIcon};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg) translateY(-50%);
    pointer-events: none;
    top: 40%;
    right: 18px;
    z-index: 1;
  }
`

export const SelectForm = styled.select`
  appearance: none;
  outline: 0;
  box-shadow: none;
  background: transparent;
  margin: 0;
  text-indent: 0;
  font-size: 13px;
  color: ${theme.colors.selectIcon};
  border: 1px solid ${theme.colors.border};
  border-radius: 30px;
  width: 100%;
  padding: 0 60px 0 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }

  > option {
    color: ${theme.colors.selectIcon};
  }
`
