import React from 'react'
import { ThemeProvider } from 'styled-components'

import GlobalStyles from './styles/global'
import { theme } from './styles/theme'

import Routes from './routes'
import { AuthProvider } from './context/AuthContext'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <Routes />
        <GlobalStyles />
        <ToastContainer />
      </ThemeProvider>
    </AuthProvider>
  )
}

export default App
