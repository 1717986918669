const colors = {
  white: '#ffffff',
  black: '#000000',
  black_1: '#212529',
  primary: '#00AA13',
  secondary: '#008C15',
  brown: '#A19060',
  grey: '#6D839A',
  purple: '#8185A5',
  yellow: '#FFC600',
  background: '#F3F3F4',
  border: '#D7D7D7',
  selectIcon: '#848484',
  red: '#de5246'
}

const fonts = {
  inter: 'Inter',
  arial: 'Arial'
}

export const theme = {
  colors,
  fonts
}
