import styled, { css } from 'styled-components'
import { theme } from '../../../styles/theme'

export const Container = styled.div`
  width: 100%;
  height: 140px;
  left: 420px;
  top: 384px;
  border: 1px solid ${theme.colors.border};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 30px;
  margin-top: 10px;

  @media (max-width: 768px) {
    height: max-content;
  }
`

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + div {
    margin-top: 21px;
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;

    & + div{
      margin-top: 5px;
    }
  }
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 33.3%;
  
  @media (max-width: 600px) {
    width: 100%;
  }
`

export const Label = styled.p`
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  color: #212529;

  @media (max-width: 600px) {
    font-size: 14px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 5px;
  }
`

interface PropsValue {
  data: boolean
}

export const Value = styled.p<PropsValue>`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #212529;

  ${(props) => props.data && css`
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: ${theme.colors.primary};
 `}
`

interface PropsButton {
  backgroundColor: string
}

export const ButtonContainer = styled.div`
  width: 33.3%;

  @media (max-width: 600px) {
    margin-top: 15px;
    width: 100%;
}
`

export const Button = styled.button<PropsButton>`
width: 70%;
display: flex;
align-items: center;
justify-content: space-between;
background: ${({ backgroundColor }) => backgroundColor || theme.colors.primary};
border-radius: 10px;
color: ${theme.colors.white};
border: 0;
padding: 10px 20px;

@media (max-width: 600px) {
    width: 100%;
}
 
`
