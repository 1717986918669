import React, { FormEvent, useState } from 'react'
import api from '../../services/api'
import { toast } from 'react-toastify'
import { Navigate } from 'react-router-dom'

import { Container, Form, Row } from './styles'

const Password: React.FC = () => {
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')
  const [verified, setVerified] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [loading, setLoading] = useState(false)
  const [validatePassword, setValidatePassword] = useState({
    upper: false,
    lower: false,
    number: false,
    special: false
  })

  function secureText (event: string): void {
    const regexUppercase = /^(?=.*[A-Z]).+$/
    const regexLowercase = /^(?=.*[a-z]).+$/
    const regexNumber = /^(?=.*[0-9]).+$/
    const regexSpecialCharacters = /^(?=.*[@$!%*#?&]).+$/

    setValidatePassword({
      upper: regexUppercase.test(event),
      lower: regexLowercase.test(event),
      number: regexNumber.test(event),
      special: regexSpecialCharacters.test(event)
    })
    setPassword(event)
  }

  async function handleSubmit (event: FormEvent): Promise<void> {
    event.preventDefault()

    const data = {
      password,
      password_confirmation: password,
      code: parseInt(code)
    }
    try {
      setLoading(true)
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      await api.patch(`/users/${params.userId}/password`, data)
      toast.success('Sucesso! Faça login para continuar!')
      setLoading(false)
      setRedirect(true)
    } catch (error: any) {
      setLoading(false)
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error)
      } else {
        toast.error('Ops! Tente novamente mais tarde')
      }
    }
  }

  return (
    <Container>
      {redirect && <Navigate to="/" />}
        <Form onSubmit={handleSubmit}>
          <h2>Crie sua senha</h2>

          <input
            placeholder='Código de segurança'
            type='text'
            maxLength={6}
            value={code}
            style={{ marginBottom: 15 }}
            onChange={event => setCode(event.target.value)}
          />

          <input
            placeholder='Senha'
            type='password'
            onChange={event => secureText(event.target.value)}
          />
          <Row>
            <span style={validatePassword.upper ? { backgroundColor: '#00A413' } : { backgroundColor: '#c4c4c4' }}>
              1 letra maiúscula
            </span>
            <span style={validatePassword.lower ? { backgroundColor: '#00A413' } : { backgroundColor: '#c4c4c4' }}>
              1 letra minúscula
            </span>
            <span style={validatePassword.special ? { backgroundColor: '#00A413' } : { backgroundColor: '#c4c4c4' }}>
              1 caractere especial
            </span>
            <span style={validatePassword.number ? { backgroundColor: '#00A413' } : { backgroundColor: '#c4c4c4' }}>
              1 número
            </span>
          </Row>

          <input
            placeholder='Confirmar senha'
            type='password'
            onChange={event => event.target.value === password ? setVerified(true) : setVerified(false)}
          />

          <button type='submit' disabled={!verified} style={!verified ? { opacity: 0.5 } : { opacity: 1 }}>
            {loading ? 'Criando...' : 'Criar'}
          </button>
        </Form>
    </Container>
  )
}

export default Password
