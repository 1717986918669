import { Container, MessageName, MessageTitle, MessageChat, MessageInputs, MessageWrapper } from './styles'
import axios from 'axios'
import ButtonOpenChat from '../ButtonOpenChat'
import React, { useState, useEffect, useContext } from 'react'
import { io } from 'socket.io-client'
import { IMAGES } from '../../assets'
import { environment } from '../../enviroment/index'
import { AuthContext } from '../../context/AuthContext'
export interface Message {
  content: string
  type?: 'operator' | 'customer'
}

export interface WebSocketPayload {
  content: any
}

export const Chat: React.FC = () => {
  const [dropdownChat, setDropdownChat] = useState(false)
  const [messages, setMessages] = useState<Message[]>([])
  const [content, setContent] = useState('')
  const [queueState, setQueueState] = useState<any>(null)
  const [wsConnection, setWsConnection] = useState<boolean>(false)
  const auth = useContext(AuthContext)

  useEffect(() => {
    if (auth.contact?.partner?.operator_id) {
      checkOperator()
      if (!wsConnection) connectWs()
    }
  }, [auth.contact?.partner?.operator_id])

  const connectWs = (): any => {
    const ws = io(environment.chatWsUrl, {
      query: {
        room: [
          `conversations::${String(auth?.user?.blueone_contact_id)}`,
          `conversations::operator::status::${String(auth?.contact?.partner?.operator_id)}::false`,
          `conversations::operator::status::${String(auth?.contact?.partner?.operator_id)}::true`
        ]
      }
    })

    ws.once('connect', () => {
      setWsConnection(ws.connected)
      ws.on('message_created', (payload: WebSocketPayload): void => {
        if (payload?.content) {
          setMessages((messages) => [...messages, {
            ...payload,
            type: 'operator'
          }])
        }
      })

      ws.on('operator_status', (payload: WebSocketPayload): void => {
        if (payload?.content) {
          setQueueState({ logged_in: payload.content === 'true' })
        }
      })
    })
  }

  const sendMessage = async (content: string, type: string): Promise<void> => {
    const api = axios.create({
      baseURL: environment.chatApiUrl,
      headers: {
        'b1-organization': environment.b1Organization
      }
    })

    await api.post('/v1/messages', {
      clientId: auth?.user?.blueone_contact_id,
      content,
      type,
      requestOrigin: environment.apiUrl.includes('stg') ? 'staging' : 'production'
    })

    setMessages((messages) => [...messages, {
      content,
      type: 'customer'
    }])
  }

  const handleSubmit = async (event?: any): Promise<void> => {
    event.preventDefault()
    if (!content || !content.length) return

    setContent('')
    await sendMessage(content, 'customer')
  }

  const handleKeyPress = async (event: any): Promise<void> => {
    if (event.key === 'Enter') {
      event.preventDefault()
      await handleSubmit(event)
    }
  }

  const checkOperator = async (): Promise<void> => {
    if (!auth.contact?.partner?.operator_id) return

    const api = axios.create({
      baseURL: environment.chatApiUrl,
      headers: {
        'b1-organization': environment.b1Organization
      }
    })

    const operatorStatus = await api.post('/v1/messages/operator', {
      operatorId: auth.contact?.partner?.operator_id,
      queueId: environment.apiUrl.includes('stg') ? '4421bba4-d9f7-45dd-8b5f-fc4bc5797dd6' : 'c246e370-68ba-4b7b-acc2-5dc1b35bd365',
      content: {
        requestOrigin: environment.apiUrl.includes('stg') ? 'staging' : 'production'
      },
      requestOrigin: environment.apiUrl.includes('stg') ? 'staging' : 'production'
    })

    setQueueState(operatorStatus.data)
  }

  return (
    dropdownChat
      ? <Container>
        <MessageWrapper>
          <MessageTitle>
            <div>
              <img src={IMAGES.MESSAGE} />
              <p>Chat Online</p>
            </div>
            <button type="button" onClick={() => setDropdownChat(!dropdownChat)}><img src={IMAGES.ARROW_DOWN} /></button>
          </MessageTitle>
          {messages.findIndex(el => el.type === 'operator') !== -1 && (<MessageName><p>Seu Gerente Comercial entrou no chat</p></MessageName>)}

          <MessageChat>

            <div id="messages">
              <div className='message operator'>
                <span>
                  {queueState?.logged_in ? 'Olá, como posso lhe ajudar?' : 'Infelizmente seu gerente comercial não está online no momento =('}
                </span>
                <strong>Bot</strong>
              </div>
              {messages.map(el => (
                <div className={`message ${el.type ?? 'operator'}`}>
                  <span>{el.content}</span>
                  <strong>{el.type === 'operator' ? 'Gerente comercial' : 'Você'}</strong>
                </div>
              ))}
            </div>
          </MessageChat>

          <MessageInputs>
          <div className="input-field">
            <input
              placeholder={queueState?.logged_in ? 'Digite a sua mensagem' : 'Indisponível'}
              type='text'
              onChange={event => setContent(event.target.value)}
              value={content}
              onKeyPress={handleKeyPress}
              disabled={!queueState?.logged_in}
            >
            </input>
              <button disabled={!queueState?.logged_in} type="button" onClick={handleSubmit}></button>
              <img src={IMAGES.SEND2} />
            </div>
          </MessageInputs>
        </MessageWrapper>
      </Container>
      : <ButtonOpenChat onClick={() => setDropdownChat(true)} />
  )
}
