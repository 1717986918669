import React, { FormEvent, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import api from '../../services/api'

import { Container, Form } from './styles'

const Forgot: React.FC = () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [userId, setUserId] = useState<string | null>(null)

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault()

    try {
      setLoading(true)
      const response = await api.post<{ userId: string }>('/users/password', {
        email
      })
      setUserId(response.data.userId)
      toast.success(`Encaminhamos um código para seu e-mail: ${email}`)
      setRedirect(true)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error)
      } else {
        toast.error('Ops! Tente novamente mais tarde')
      }
    }
  }

  return (
    <Container>
    {redirect && !!userId && <Navigate to={`/password?userId=${userId}`} />}
      <Form onSubmit={handleSubmit}>
        <h2>Para continuar, informe o seu e-mail</h2>
        <input
            placeholder='E-mail'
            type='email'
            value={email}
            required
            onChange={event => setEmail(event.target.value)}
        />
        <button type="submit">{loading ? 'Enviando...' : 'Enviar'}</button>
        </Form>
    </Container>
  )
}
export default Forgot
