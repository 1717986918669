import React, { useContext, useEffect, useState } from 'react'
import { BackLink } from '../../components'
import { Container, Titles, CardContainer, Cardkpi, Labelkpi, ChartContainer } from './styles'
import ChartVertical from './components/ChartVertical'
import ChartHorizontal from './components/ChartHorizontal'
import api from '../../services/api'
import { AuthContext } from '../../context/AuthContext'

const Dashboard: React.FC<any> = () => {
  const auth = useContext(AuthContext)

  const [indications, setIndications] = useState<any[]>([])
  const [statusIndications, setStatusIndications] = useState<any[]>([])

  const loadIndications = async (): Promise<void> => {
    try {
      const data = await api.get(`/indications/${String(auth?.user?.blueone_contact_id)}`)
      setIndications(data.data.data)
      setStatusIndications(data.data.data.filter((data: any) => data.data.status_gn))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    loadIndications()
  }, [])

  const prospeccaoIndicator = indications.filter((indication: any) => indication.step_name === 'Prospecção').length
  const triagemIndicator = indications.filter((indication: any) => indication.step_name === 'Triagem').length
  const leadIndicator = indications.filter((indication: any) => indication.step_name === 'Lead').length

  const propostaEmAprovacao = statusIndications.filter((indication: any) => indication.data.status_gn === 'Proposta em Aprovação').length
  const propostaEnviada = statusIndications.filter((indication: any) => indication.data.status_gn === 'Proposta Enviada ao Cliente').length
  const propostaEmNegociacao = statusIndications.filter((indication: any) => indication.data.status_gn === 'Proposta em Negociação').length
  const propostaRenovada = statusIndications.filter((indication: any) => indication.data.status_gn === 'Proposta Renovada').length
  const contratoAtivo = statusIndications.filter((indication: any) => indication.data.status_gn === 'Contrato Ativo').length
  const contratoCreditado = statusIndications.filter((indication: any) => indication.data.status_gn === 'Contrato Creditado').length
  const declinado = statusIndications.filter((indication: any) => indication.data.status_gn === 'Declinado').length

  const indicaçãoEnviada = indications.filter((indication: any) => indication.step_name === 'Indicação enviada').length

  const indicatorsArray = [prospeccaoIndicator, triagemIndicator, leadIndicator]
  const statusIndicatorsArray = [propostaEmAprovacao, propostaEnviada, propostaEmNegociacao, propostaRenovada, contratoAtivo, contratoCreditado, declinado]

  const totalPendente = prospeccaoIndicator + triagemIndicator + leadIndicator

  return (
    <Container>
      <BackLink link="/" />
      <Titles>Consolidado</Titles>
      <CardContainer>
        <Cardkpi>
          <p>Indicações Enviadas</p>
          <Labelkpi>{indicaçãoEnviada}</Labelkpi>
        </Cardkpi>
        <Cardkpi>
          <p>Total Creditado</p>
          <Labelkpi>{contratoCreditado}</Labelkpi>
        </Cardkpi>
        <Cardkpi>
          <p>Total Em Negociação</p>
          <Labelkpi>{propostaEmNegociacao}</Labelkpi>
        </Cardkpi>
        <Cardkpi>
          <p>Total Pendentes</p>
          <Labelkpi>{totalPendente}</Labelkpi>
        </Cardkpi>
      </CardContainer>
      <ChartContainer>
        <div>
          <Titles>Status da indicação</Titles>
          <div>
          <ChartVertical indicators={statusIndicatorsArray}></ChartVertical>
          </div>
        </div>
        <div>
          <Titles>Status pendente parceiro</Titles>
          <div>
          <ChartHorizontal indicators={indicatorsArray}></ChartHorizontal>
          </div>
        </div>
      </ChartContainer>
    </Container>
  )
}
export default Dashboard
