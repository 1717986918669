// import React, { useContext } from 'react' //Ocultando o chat

import { Header } from '../components'
// import { AuthContext } from '../context/AuthContext' //Ocultando o chat

import { Container, TopMenu, MainContent, Wrapper } from './styles'

const Layout: React.FC = ({ children }) => {
  // const { isAuthenticated } = useContext(AuthContext) //Ocultando o chat
  return (
    <Container>
      <TopMenu>
        <Header />
      </TopMenu>
      <Wrapper id="wrapper">
        <MainContent id="main-content">{children}</MainContent>
        {/* Ocultando o chat */}
        {/* {isAuthenticated && <Chat />} */}
      </Wrapper>
    </Container>
  )
}

export default Layout
