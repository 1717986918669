import api from '../../../../services/api'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  InputContainer
} from '../../styles'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { InputSelectSchedule, Div } from './style'

interface ScheduleFormProps {
  submit: boolean
  lead: any
  disable?: boolean
  handleFormData: (responseFromForm?: any) => void
}

const schemaTriage = yup.object().shape({
  date: yup.string().trim().required(),
  description: yup.string().trim().required(),
  is_schedule: yup.string().trim().required()
}).required()

const ScheduleForm: React.FC<ScheduleFormProps> = (props: ScheduleFormProps) => {
  const [schedule, setSchedule] = useState<boolean>(false)

  const formElement = useRef<HTMLFormElement | null>(null)
  const { register, handleSubmit, formState: { isDirty } } = useForm({
    defaultValues: {
      date: props.lead.data.schedule.date ?? '',
      description: props.lead.data.schedule.description ?? '',
      is_schedule: props.lead.data.schedule.is_schedule ?? ''
    },
    resolver: yupResolver(schemaTriage)
  })

  const handleSchedule = (e: any): void => {
    e.preventDefault()
    const value = e.target.value
    value === 'true' ? setSchedule(true) : setSchedule(false)
  }

  const onSubmit = async (data: any, e: any): Promise<void> => {
    e.preventDefault()
    if (!(await schemaTriage.isValid(data))) {
      toast.error('Ocorreu um erro, verifique se os dados preenchidos estão corretos e tente novamente!')
      return
    }

    if (!isDirty) return

    const scheduleData = {
      date: data.date,
      description: data.description,
      is_schedule: data.is_schedule
    }

    const payload = {
      schedule: scheduleData
    }

    try {
      await api.put(`/indications/${String(props.lead?.id)}`, payload)
    } catch (error) {
      console.log('error', error)
    }

    props.handleFormData(payload)
  }

  useEffect(() => {
    if (props.lead.data.schedule.is_schedule === 'true' || props.lead.data.schedule.is_schedule === true) {
      setSchedule(true)
    }
  }, [])

  useEffect(() => {
    if (props.submit && formElement.current) formElement.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }, [props.submit])

  return (
    <form ref={formElement} onSubmit={handleSubmit(onSubmit)}>
      <Div>
        <InputSelectSchedule>
          <label>Agendou reunião?</label>
          <select
            {...register('is_schedule')}
            disabled={props.disable}
            onChange={(e) => handleSchedule(e)}>
            <option value='true'>Sim</option>
            <option value='false'>Não</option>
          </select>
        </InputSelectSchedule>
      </Div>
      {
        schedule && (
          <Div>
            <InputContainer>
              <label>Data do agendamento</label>
              <input
                disabled={props.disable}
                type='datetime-local'
                {...register('date')}
                required={schedule}
              />
            </InputContainer>
            <InputContainer>
              <label>Descrição</label>
              <input
                disabled={props.disable}
                type='text'
                {...register('description')}
                required={schedule}
              />
            </InputContainer>
          </Div>
        )
      }
    </form>
  )
}

export default ScheduleForm
