import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`
export const Titles = styled.div`
  font-family: ${theme.fonts.arial};
  font-size: 14px;
  font-weight: 700;
  color: ${theme.colors.grey};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 16px;
`
export const CardContainer = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 25px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 18px;

  @media only screen and (max-width: 425px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
export const Cardkpi = styled.div`
  margin: 0 auto;
  padding: 0 0.25rem;

  max-width: 175px;
  width: 100%;
  height: 95px;

  background: #D7ECFF;
  border-radius: 14px;

  p{
    margin-top: 7px;

    text-align: center;
    color: ${theme.colors.grey};

    font-size: 14px;
    font-weight: 500;
    font-family: ${theme.fonts.arial};
  }
`
export const Labelkpi = styled.h1`
  margin-top: 8px;
  font-size: 35px;
  text-align: center;
  font-family: ${theme.fonts.arial};
  font-style: normal;
  font-weight: 700;
  color: ${theme.colors.secondary};
  margin-left: 5px;
  margin-right: 5px;
  border-left: 6px solid ${theme.colors.yellow};
`
export const ChartContainer = styled.div`
  margin-top: 1rem;
  padding-left: 0.5rem;

  display: flex;
  justify-content: space-around;
  gap: 20px;

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-bottom: 10rem;
  }
`
