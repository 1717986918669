import styled from 'styled-components'
import { theme } from '../../styles/theme'

interface ContainerProps {
  loggedIn: boolean
}

interface ButtonProps {
  backgroundColor: string
}

export const Container = styled.div<ContainerProps>`
  padding-top: 4px;
  height: 100%;
  z-index: 1;
  background-color: ${theme.colors.primary};
  border-bottom: 1px solid ${theme.colors.secondary};
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.loggedIn ? 'space-around' : 'center')};
  align-items: center;

  .logo-vr {
    width: 50px;
    height: 50px;
  }

  @media (max-width: 768px){
    .logo-vr{
      margin: calc((100vw - 50px) / 2);
    }
  }
`
interface MenuProps {
  checked: boolean
}

export const MenuHamburguer = styled.div<MenuProps>`
  text-decoration: none;
  .navbar{
    display: none;
  }
  
  @media (max-width: 768px){
    .navbar{
      display: block;
      width: 200px;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;

    }
    .navbar-container {
    display: block;
    position: relative;
    height: 64px;
    }
    .navbar-container input[type="checkbox"] {
      position: absolute;
      display: block;
      height: 32px;
      width: 30px;
      top: 20px;
      left: 20px;
      z-index: 5;
      opacity: 0;
    }
    .navbar-container .hamburger-lines {
      display: block;
      height: 23px;
      width: 35px;
      position: absolute;
      top: 17px;
      left: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .navbar-container .hamburger-lines .line {
      display: block;
      height: 4px;
      width: 100%;
      border-radius: 10px;
      background: ${theme.colors.yellow}
    }
    .navbar-container .hamburger-lines .line1 {
      transform-origin: 0% 0%;
      transition: transform 0.4s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2 {
      transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3 {
      transform-origin: 0% 100%;
      transition: transform 0.4s ease-in-out;
    }
    .navbar .menu-items {
      padding-top: 100px;
      background: #fff;
      height: 100vh;
      max-width: 300px;
      display: flex;
      flex-direction: column;
      margin-left: -40px;
      padding-left: 50px;
      transition: transform 0.5s ease-in-out;
      box-shadow: 1px 1px 6px 1px rgba(0, 0, 0, 0.25);
    }
    .navbar .menu-items li {
      margin-bottom: 2.5rem;
      font-size: 20px;
      font-weight: 500;
      display: flex;
      gap: 10px;
    }
    .navbar .menu-items a {
      text-decoration: none;
      color: #20475B;
    }
    .navbar-container input[type="checkbox"] ~ .menu-items {
      transform: ${props => (props.checked ? 'translateX(0)' : 'translate(-250%)')};
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
      transform: rotate(36deg);
    }
    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
      transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
      transform: rotate(-36deg);
    }
  }
`

export const MenuBar = styled.div`

  width: 50%;
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 28px;


  a {
    color: ${theme.colors.white};
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
  }

  @media (max-width: 768px){
    display: none;
  }
  

`

export const Button = styled.button<ButtonProps>`
  background: #bdbebd;
  border-radius: 4px;
  min-width: 128px;
  height: 40px;
  color: ${theme.colors.white};
  border: none;
  padding: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;

  .logo-indicacoes{
      margin-right: 5px
  }
`

export const Profile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 0.5rem;
  position: relative;

  .profile-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: ${theme.colors.yellow};
    margin-right: 10px;
    color: ${theme.colors.white};
    font-weight: 600;
    font-size: .8rem;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: ${theme.colors.white};
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 12px 16px;
    z-index: 1;
    height: max-content;
    border-radius: 4px;
    top: 100%;
    left: 0;
    
    .dropdown-item {
      color: ${theme.colors.black};
      list-style: none;
      padding: 10px;

      &:hover {
        background-color: ${theme.colors.yellow};
        border-radius: 5px;
      }
    }
  }

  &:hover .dropdown-content {
    display: block;
  } 
`

export const BankAccount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p{
    color: ${theme.colors.white};
    margin: 5px;
  }

`
