import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
    color: #212529;
    margin-bottom: 10px;
  }

  input {
    padding: 0 1.5rem;
    height: 3rem;
    width: 100%;
    border-radius: 10px;
    background: transparent;
    border: 1px solid ${theme.colors.border};

    font-weight: 400;
    font-size: 1.125rem;
  }
`
