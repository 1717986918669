import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Container = styled.div`
  width: 100%;

  padding: 5%;

  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;

  h2 {
    color: ${theme.colors.black};
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  input{
    padding: 0 1.5rem;
    margin-bottom: 1.5rem;
    height: 3rem;
    width: 25rem;
    border-radius: 10px;

    border: 1px solid ${theme.colors.border};

    font-weight: 400;
    font-size: 1.125rem;
  }

  button{
    width: 100%;
    height: 55px;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    font-size: 1.25rem;
    border-radius: 10px;
    font-weight: 600;

    margin-top: 1.5rem;
    border: none;

    &:hover {
      border: 2px solid ${theme.colors.secondary};
    }
  }

  .register-message {

    font-weight: 600;
    color: ${theme.colors.black};
    margin-top: 1rem;

    a {
      color: ${theme.colors.secondary};
      text-decoration: none;

      &:hover {
        color: ${theme.colors.primary};
      }
    }
  }

  @media only screen and (max-width: 768px) {
    input{
      max-width: 25rem;
      width: 100%;
    }
  }
`
