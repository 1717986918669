import React from 'react'

import { Container, MainContent } from './styles'

const Regulations: React.FC = () => {
  return (
    <Container>
      <MainContent>
        <h2>Regulamento</h2>
        <iframe height="100%" src="./regulamento.pdf"></iframe>
      </MainContent>
    </Container>
  )
}

export default Regulations
