import React from 'react'
import { useNavigate } from 'react-router-dom'

import { IMAGES } from '../../assets'

import { Container } from './styles'

interface Props {
  link: string
}

const BackLink: React.FC<Props> = ({ link }) => {
  const navigate = useNavigate()

  return (
    <Container>
      <img src={IMAGES.ARROW_LEFT} />
      <span onClick={() => navigate(link)}>Voltar</span>
    </Container>
  )
}

export default BackLink
