import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Layout from '../layout'

import { SingIn, Forgot, Register, Password, Regulations, Redirect } from '../pages'

const PublicRoutes: React.FC = () => (
    <Layout>
      <Routes>
        <Route path="/" element={<SingIn />} />
        <Route path="/b1/:contactId" element={<Redirect />} />
        <Route path="/register" element={<Register />} />
        <Route path="/password" element={<Password />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/regulations" element={<Regulations />} />
      </Routes>
    </Layout>
)

export default PublicRoutes
