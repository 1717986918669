import {
  Container,
  Title,
  Header,
  Name,
  Subscribe,
  // Filter,
  ContainerIndication,
  FilterContainer,
  FilterWrapper,
  ButtonClearFilter,
  ButtonFilter,
  ButtonNewIndication
} from './styles'
import React, { InputHTMLAttributes, SelectHTMLAttributes, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { BackLink, Cards } from '../../components'
import { AuthContext } from '../../context/AuthContext'
import api from '../../services/api'
import moment from 'moment'
import { toast } from 'react-toastify'
import { deleteData, getData, setDataObject } from '../../utils/localstorage'

interface filtersHistory {
  cnpj?: string
  date?: string
  status?: string
}

type filterKey = 'cnpj' | 'date' | 'status'

const Indications: React.FC = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  const [indications, setIndications] = useState<any[]>([])
  const [newIndications, setNewIndications] = useState<any[]>([])
  const [inputFilter, setInputFilter] = useState<any>('')

  const userName = `${auth.contact?.profile?.first_name as string} ${auth.contact?.profile?.last_name as string}`

  const [LABELS_INDICATORS] = useState<any[]>([
    'CNPJ',
    'Etapa',
    'Protocolo',
    'Data da última atualização',
    'Status Proposta'
  ])

  const $status = document.querySelector('[name="status"]') as SelectHTMLAttributes<any>
  const $date = document.querySelector('[name="date"]') as InputHTMLAttributes<any>
  const $cnpj = document.querySelector('[name="cnpj"]') as InputHTMLAttributes<any>

  const STORAGE_FORM_INDICATIONS_KEY = '@portalvr/form/indications'

  useEffect(() => {
    loadIndications()
  }, [])

  useEffect(() => {
    handleFilter()
  }, [indications])

  const loadIndications = async (): Promise<void> => {
    try {
      const data = await api.get(`/indications/${String(auth?.user?.blueone_contact_id)}`)
      setIndications(data?.data?.data?.filter((indication: any) => indication?.data?.status_gn !== 'Contrato Ativo' && indication?.data?.status_gn !== 'Contrato Creditado' && indication?.data?.status_gn !== 'Declinado'))
    } catch (error) {
      console.log(error)
    }
  }

  const handleFilter = (): void => {
    const filter: filtersHistory = getData(STORAGE_FORM_INDICATIONS_KEY)
    if (!filter || indications.length === 0) return
    filterHistory(filter)
  }

  const handleInputChange = (e: any): void => {
    const getInput = e.target.value

    setInputFilter(getInput)
  }

  const filterCNPJ = (): void => {
    const oldIndications = indications

    setDataObject(STORAGE_FORM_INDICATIONS_KEY, { cnpj: inputFilter })

    let newIndicationFiltred = oldIndications.filter((indication) => (
      indication.steps.find((step: any) => step.name === 'Prospecção').data.cnpj === inputFilter.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') || indication.data.prospection.cnpj === inputFilter
    ))

    if (newIndicationFiltred.length === 0) {
      newIndicationFiltred = oldIndications.filter((indication) => (
        indication.data.prospection.cnpj === inputFilter
      ))
      if (newIndicationFiltred.length === 0) {
        toast.error('Dados não encontrados para esse filtro')
      }
    }

    setNewIndications(newIndicationFiltred)
  }

  const filterData = (): void => {
    const oldIndications = indications

    setDataObject(STORAGE_FORM_INDICATIONS_KEY, { date: inputFilter })

    const newDate = oldIndications.map((item) => {
      const formatedDate = moment(item.updated_at).format('YYYY-MM-DD')

      return {
        ...item,
        formatedDate
      }
    })

    const newIndicationFiltred = newDate.filter((indication) => (
      indication.formatedDate === inputFilter
    ))

    if (newIndicationFiltred.length === 0) {
      toast.error('Dados não encontrados para esse filtro')
    }

    setNewIndications(newIndicationFiltred)
  }

  const filterStatus = (): void => {
    const oldIndications = indications

    setDataObject(STORAGE_FORM_INDICATIONS_KEY, { status: inputFilter })

    const newIndicationFiltred = oldIndications.filter((indication) => (
      indication.step_name === inputFilter
    ))

    if (newIndicationFiltred.length === 0) {
      toast.error('Dados não encontrados para esse filtro')
    }

    setNewIndications(newIndicationFiltred)
  }

  const filterHistory = (filterHistory: filtersHistory): void => {
    const oldIndications = indications

    const byCNPJ = (cnpj: string): void => {
      let newIndicationFiltred = oldIndications.filter((indication) => (
        indication.steps.find((step: any) => step.name === 'Prospecção').data.cnpj === inputFilter.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') || indication.data.prospection.cnpj === cnpj
      ))

      if (newIndicationFiltred.length === 0) {
        newIndicationFiltred = oldIndications.filter((indication) => (indication.data.prospection.cnpj === cnpj))
        if (newIndicationFiltred.length === 0) toast.error('Dados não encontrados para esse filtro')
      }

      $cnpj.value = cnpj
      setNewIndications(newIndicationFiltred)
    }

    const byDate = (date: string): void => {
      const newDate = oldIndications.map((item) => {
        const formatedDate = moment(item.updated_at).format('YYYY-MM-DD')

        return {
          ...item,
          formatedDate
        }
      })

      const newIndicationFiltred = newDate.filter((indication) => (indication.formatedDate === date))

      if (newIndicationFiltred.length === 0) toast.error('Dados não encontrados para esse filtro')
      $date.value = date
      setNewIndications(newIndicationFiltred)
    }

    const byStatus = (status: string): void => {
      const newIndicationFiltred = oldIndications.filter((indication) => (indication.step_name === status))

      if (newIndicationFiltred.length === 0) toast.error('Dados não encontrados para esse filtro')

      setNewIndications(newIndicationFiltred)
      $status.value = status
    }

    const filterStoraged = {
      cnpj: byCNPJ,
      date: byDate,
      status: byStatus
    }

    const filterType = Object.keys(filterHistory)[0] as filterKey
    const filterValue = Object.values(filterHistory)[0]
    filterStoraged[filterType](filterValue)
  }

  const cleanFilter = (e: any): void => {
    e.preventDefault()
    deleteData(STORAGE_FORM_INDICATIONS_KEY)
    setNewIndications([])
    $cnpj.value = ''
    $date.value = ''
    $status.value = ''
  }

  const verifyFormProfaleValue = (): void => {
    const allowCreateANewInication = localStorage.getItem('@portalvr/form')

    if (allowCreateANewInication === 'true') {
      navigate('/indication/create')
    }

    if (!allowCreateANewInication || allowCreateANewInication === 'false') {
      toast.error('Favor completar o seu perfil', { closeButton: true })
      navigate('/')
    }
  }

  return (
    <Container>
      <BackLink link='/' />
      <Title>Indicações</Title>
      <Header>
        <div>
          <Name>Olá, {userName}!</Name>
          <Subscribe>Você tem {indications.length || '-'} {indications.length === 1 ? 'indicação' : 'indicações'} em andamento</Subscribe>
        </div>

        <ButtonNewIndication onClick={() => verifyFormProfaleValue()}>
          Nova Indicação
        </ButtonNewIndication>
      </Header>
      <FilterContainer>
        <FilterWrapper>
          <div>
            <label>Data</label>
            <input type='date' name='date' onChange={handleInputChange} />
            <ButtonFilter type='button' onClick={filterData}>Pesquisar</ButtonFilter>
          </div>
          <div>
            <label>CNPJ</label>
            <input type='text' name='cnpj' placeholder='Digite o CNPJ' onChange={handleInputChange}></input>
            <ButtonFilter type='button' onClick={filterCNPJ}>Pesquisar</ButtonFilter>
          </div>
        </FilterWrapper>
        <FilterWrapper>
          <div>
            <label>Etapa</label>
            <select name='status' onChange={handleInputChange}>
              <option value="">Selecione</option>
              <option value="Indicação enviada">Indicação enviada</option>
              <option value="Prospecção">Prospecção</option>
              <option value="Triagem">Triagem</option>
              <option value="Lead">Lead</option>
            </select>
            <ButtonFilter type='button' onClick={filterStatus}>Pesquisar</ButtonFilter>
          </div>
          <div>
            <ButtonClearFilter onClick={(event) => cleanFilter(event)}>Limpar Filtros</ButtonClearFilter>
          </div>
        </FilterWrapper>

      </FilterContainer>
      <ContainerIndication>
        <Cards labels={LABELS_INDICATORS} indicationsData={newIndications.length === 0 ? indications : newIndications} />
      </ContainerIndication>
    </Container>
  )
}

export default Indications
