import styled from 'styled-components'

export const Container = styled.div`

  padding: 10px;
  width: 90%;
  margin: 0 auto;


  @media (max-width: 460px) {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

`
