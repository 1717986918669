import React from 'react';
import { useLocation } from 'react-router-dom';

import { BackLink } from '../../components';
import { maskCNPJ, maskPhone, maskReais } from '../../utils/masks';

import {
	Container,
	Step,
	Title,
	Box,
	Div,
	Header,
	Name,
	Info,
	Label,
	Description,
} from './styles';

const SalesDetails: React.FC = () => {
	const {
		state: {
			data: { steps },
		},
	}: any = useLocation();

	const prospection = steps?.find((step: any) => step.name === 'Prospecção');
	const lead = steps?.find((step: any) => step.name === 'Lead');
	const triagem = steps?.find((step: any) => step.name === 'Triagem');

	const rhName = `${prospection?.data?.contact?.first_name as string} ${
		prospection?.data?.contact?.last_name as string
	}`;

	const DATA = [
		{ text: 'VR Multi Alimentação', value: '11' },
		{ text: 'VR Multi Refeição', value: '12' },
		{ text: 'VR Multi Aux. VR + VA', value: '13' },
		{ text: 'VR Multi Aux. Home Office', value: '14' },
		{ text: 'VR Multi Mobilidade', value: '15' },
		{ text: 'VR Vale Transporte', value: '16' },
		{ text: 'VR Multi Aux. Alimentação', value: '17' },
		{ text: 'VR Multi Aux. Refeição', value: '18' },
		{ text: 'VR Gente - Pontomais', value: '19' },
	];

	return (
		<Container>
			<BackLink link="/sales" />
			<Header>
				<div>
					<Name>Detalhes da venda</Name>
				</div>
			</Header>

			<Step>
				<Title>1. Prospecção</Title>
				<Box>
					<Div>
						<Info>
							<Label>Razão social</Label>
							<Description>
								{prospection?.data?.social_reason || ''}
							</Description>
						</Info>
						<Info>
							<Label>Contato RH</Label>
							<Description>{rhName || ''}</Description>
						</Info>
						<Info>
							<Label>Telefone</Label>
							<Description>
								{maskPhone(prospection?.data?.contact?.phone || '') || ''}
							</Description>
						</Info>
					</Div>
					<Div>
						<Info>
							<Label>CNPJ</Label>
							<Description>
								{maskCNPJ(prospection?.data?.cnpj || '') || ''}
							</Description>
						</Info>
						<Info>
							<Label>Função</Label>
							<Description>
								{prospection?.data?.contact?.professional_role || ''}
							</Description>
						</Info>
						<Info>
							<Label>E-mail</Label>
							<Description>
								{prospection?.data?.contact?.email || ''}
							</Description>
						</Info>
					</Div>
				</Box>
			</Step>
			<Step>
				<Title>2. Triagem</Title>
				<Box>
					<Div>
						<Info>
							<Label>Quem é o decisor?</Label>
							<Description>
								{triagem?.data?.who_is_decision_maker || ''}
							</Description>
						</Info>
						<Info>
							<Label>Empresa é cadastrada no PAT?</Label>
							<Description>{triagem?.data?.pat || ''}</Description>
						</Info>
						<Info>
							<Label>Oferece algum benefício?</Label>
							<Description>{triagem?.data?.current_supplier || ''}</Description>
						</Info>
						<Info>
							<Label>Atual fornecedor</Label>
							<Description>{triagem?.data?.current_supplier}</Description>
						</Info>
					</Div>
				</Box>
			</Step>
			<Step>
				<Title>3. Lead</Title>
				<Box>
					{((lead?.data?.benefits as []) || []).map(
						(
							benefit: { benefit: string; quantity: string; price: string },
							index,
						) => (
							<Div key={index}>
								<Info>
									<Label>Benefícios</Label>
									<Description>
										{DATA.map(({ text, value }) => {
											if (benefit.benefit === value) {
												return text;
											}
											return '';
										})}
									</Description>
								</Info>
								<Info>
									<Label>Quantidade</Label>
									<Description>{benefit.quantity || ''}</Description>
								</Info>
								<Info>
									<Label>Valor</Label>
									<Description>
										{maskReais(benefit.price || '') || ''}
									</Description>
								</Info>
							</Div>
						),
					)}
					<Div>
						<p>
							Atual fornecedor oferece algum benefício adicional? Taxa Zero?
							Desconto farmácia? Programa de assistência?
						</p>
					</Div>
					<Div>
						<Info>
							<div>
								<input
									type="checkbox"
									name="nutrition_vr"
									defaultChecked={lead?.data?.nutrition_vr}
									disabled={true}
								/>
								<label>VR Nutrição</label>
							</div>
						</Info>
						<Info>
							<div>
								<input
									type="checkbox"
									name="is_pae"
									defaultChecked={lead?.data?.is_pae}
									disabled={true}
								/>
								<label>PAE</label>
							</div>
						</Info>
						<Info>
							<div>
								<input
									type="checkbox"
									name="pharmacy_discount"
									defaultChecked={lead?.data?.pharmacy_discount}
									disabled={true}
								/>
								<label>Desconto Farmácia</label>
							</div>
						</Info>
						<Info>
							<div>
								<input
									type="checkbox"
									name="total_pass"
									defaultChecked={lead?.data?.total_pass}
									disabled={true}
								/>
								<label>Total Pass</label>
							</div>
						</Info>
						<Info>
							<div>
								<input
									type="checkbox"
									name="food_insurance"
									defaultChecked={lead?.food_insurance}
									disabled={true}
								/>
								<label>Seguro Alimentação</label>
							</div>
						</Info>
						<Info>
							<div>
								<input
									type="checkbox"
									name="marketplace_or_loyalty_vr"
									defaultChecked={lead?.marketplace_or_loyalty_vr}
									disabled={true}
								/>
								<label>VR Fidelidade</label>
							</div>
						</Info>
						<Info>
							<div>
								<input
									type="checkbox"
									name="rebate_or_value"
									defaultChecked={lead?.rebate_or_value}
									disabled={true}
								/>
								<label>Rebate/Valor</label>
							</div>
						</Info>
						<Info>
							<div>
								<input
									type="checkbox"
									name="payment_term"
									defaultChecked={lead?.payment_term}
									disabled={true}
								/>
								<label>Prazo de Pagamento</label>
							</div>
						</Info>
					</Div>
				</Box>
			</Step>
		</Container>
	);
};

export default SalesDetails;
