import styled, { css } from 'styled-components'
import { theme } from '../../styles/theme'

export const Container = styled.div`
  width: 80%;
  height: 80vh;
  overflow-y: auto;
  padding-right: 10px;
  padding-bottom: 1rem;
  
  @media (max-width: 768px) {
    height: 90vh;
  }
`

export const Step = styled.div`
  width: 100%;

  & + div {
    margin-top: 30px;
  }

`

export const Header = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  p{
    font-weight: 600;
    color: ${theme.colors.black};
  }
  
`
export const Name = styled.p`
  font-size: 18px;
  line-height: 22px;
`

export const Div = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-around;

  & + div {
    margin-top: 30px;
  }

  div {
    & + div {
      margin-left: 10px;
    }
  }
  
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;

    & + div {
      margin-top: 0px;
    }
  }
`

export const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${theme.colors.black};
  margin-bottom: 15px;
`

export const Box = styled.div`
  width: 100%;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.border};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px;

  @media (max-width: 600px) {
      label{
        margin-top: 15px;
      }
      input{
        margin-bottom: 15px;
      }
  }

`

export const ButtonContainer = styled.div<{ flex?: boolean }>`
  ${(props) => {
    if (props.flex) {
      return css`
        display: flex;
        justify-content: center;
        gap: 16px;

        & > button {
          margin: 0;
        }
      `
    } else {
      return css`
        width: 100%;
        display: block;
      `
    }
  }}
`

export const Button = styled.button<{ backgroundColor?: string }>`
  width: 20%;
  margin: 0 auto 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  opacity: 1;
  background: ${(props) => props.backgroundColor ?? theme.colors.primary};
  border-radius: 5px;
  color: ${theme.colors.white};
  border: 0;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;

  &:disabled {
    opacity: 0.5;
  }

  @media (max-width: 600px) {
    width: 80%;
  }

`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
    color: #212529;
    margin-bottom: 10px;
  }

  input {
    padding: 0 1.5rem;
    height: 3rem;
    width: 100%;
    border-radius: 10px;
    background: transparent;
    border: 1px solid ${theme.colors.border};

    font-weight: 400;
    font-size: 1.125rem;
  }

  input:disabled {
    opacity: 0.5;
  }

  select{
  outline: 0;
  box-shadow: none;
  background: transparent;
  margin: 0;
  text-indent: 0;
  font-size: 13px;
  color: ${theme.colors.selectIcon};
  border: 1px solid ${theme.colors.border};
  border-radius: 30px;
  width: 100%;
  padding: 0 60px 0 15px;
  height: 3rem;
  cursor: pointer;

  select:disabled {
    opacity: 0.5;
  }

  &::-ms-expand {
    display: none;
  }

  > option {
    color: ${theme.colors.selectIcon};
  }
  }
`

export const CheckBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 425px) {
    width: 50%;
    margin: 0 auto;
    padding-top: 0.5rem;
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

export const CheckBoxContainer = styled.div`
  padding: 0.5rem 0.30rem 0;

  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: max-content;

  label {
    font-weight: 500;
    font-size: 12px;
    color: #212529;
    margin-left: 0.25rem;
  }

  input {
    height: 1rem;
    width: 1rem;
    background: transparent;
    border: 1px solid ${theme.colors.border};
  }

  @media only screen and (max-width: 768px) {
    label, input {  
      margin: 0;
    }

    label {
      margin-left: 0.25rem;
    }
  }
`
