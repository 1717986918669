import styled from 'styled-components'
import { theme } from '../../../../styles/theme'

export const Div = styled.div`
  display: flex;

  & + div {
    margin-top: 30px;
  }

  div {
    & + div {
      margin-left: 10px;
    }
  }
  
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;

    & + div {
      margin-top: 0px;
    }
  }
`

export const InputSelectSchedule = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 20%;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
    color: #212529;
    margin-bottom: 10px;
  }

  input {
    padding: 0 1.5rem;
    height: 3rem;
    width: 100%;
    border-radius: 10px;
    background: transparent;
    border: 1px solid ${theme.colors.border};

    font-weight: 400;
    font-size: 1.125rem;
  }

  select{
  outline: 0;
  box-shadow: none;
  background: transparent;
  margin: 0;
  text-indent: 0;
  font-size: 13px;
  color: ${theme.colors.selectIcon};
  border: 1px solid ${theme.colors.border};
  border-radius: 30px;
  width: 100%;
  padding: 0 60px 0 15px;
  height: 3rem;
  cursor: pointer;

  &::-ms-expand {
    display: none;
  }

  > option {
    color: ${theme.colors.selectIcon};
  }
  }
  @media only screen and (max-width: 425px) {

    width: 100%;

    select, input{
      width: 100%;
      margin-bottom: 0.5rem;
    }

    label {
      margin-left: 0.25rem;
    }
  }
`
