import React, { FormEvent, useState } from 'react'
import api from '../../services/api'
import { toast } from 'react-toastify'

import { Container, Form, CheckboxContainer } from './styles'
import { Link, Navigate } from 'react-router-dom'

const Register: React.FC = () => {
  const [email, setEmail] = useState('')
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [userId, setUserId] = useState<string | null>(null)

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    event.preventDefault()

    try {
      setLoading(true)
      const response = await api.post<{ userId: string }>('/users/password', {
        email
      })
      setUserId(response.data.userId)
      toast.success(`Encaminhamos um código para seu e-mail: ${email}`)
      setRedirect(true)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error)
      } else {
        toast.error('Ops! Tente novamente mais tarde')
      }
    }
  }

  return (
    <Container>
        {redirect && !!userId && <Navigate to={`/password?userId=${userId}`} />}
        <Form onSubmit={handleSubmit}>
          <h2>Criar conta</h2>

          <input
            placeholder='E-mail'
            type='email'
            value={email}
            required
            onChange={event => setEmail(event.target.value)}
          />

          <CheckboxContainer>
            <input required onClick={() => setAcceptedTerms(!acceptedTerms)} id="regulamentation" type="checkbox" />
            <label htmlFor="regulamentation">Eu concordo com o <Link target="_blank" to="/regulations">Regulamento do <br />Programa “Parceiro VR”</Link>.</label>
          </CheckboxContainer>

          <button type='submit'>
            {loading ? 'Criando...' : 'Criar'}
          </button>
        </Form>
    </Container>
  )
}

export default Register
