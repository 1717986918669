import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Container = styled.div`
  width: 100%;

  padding: 5%;

  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  h2{
    color: ${theme.colors.black};
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 2rem;
    align-self: flex-start;
  }

  input{
    padding: 0 1.5rem;
    height: 3rem;
    width: 25rem;
    border-radius: 10px;

    border: 1px solid ${theme.colors.border};

    font-weight: 400;
    font-size: 1.125rem;
  }

  button{
    width: 100%;
    height: 55px;
    background-color: ${theme.colors.primary};
    color: ${theme.colors.white};
    font-size: 1.25rem;
    border-radius: 10px;
    font-weight: 600;

    margin-top: 1.5rem;
    border: none;

    &:hover {
      border: 2px solid ${theme.colors.secondary};
    }
  }
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin: 1rem 0;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c4c4c4;
    color: ${theme.colors.white};
    border-radius: 15px;
    font-weight: 600;
    padding: .1rem .8rem;
    font-size: 12px;
  }
`
