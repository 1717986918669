import React from 'react'
import { Routes, Route } from 'react-router-dom'

import Layout from '../layout'

import {
  Home,
  Profile,
  Regulations,
  Register,
  Indications,
  CreateIndicators,
  Sales,
  SalesDetails,
  IndicationsDetails,
  Dashboard,
  Training,
  ChangePassword
} from '../pages'

const PrivateRoutes: React.FC = () => (
  <Layout>
    <Routes>
      <Route path="/" element={<Profile />} />
      <Route path="/home" element={<Home />} />
      <Route path='/training' element={<Training />} />
      <Route path='/dashboard' element={<Dashboard />} />
      <Route path="/register" element={<Register />} />
      <Route path="/regulations" element={<Regulations />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/indications" element={<Indications />} />
      <Route path="/indication/create" element={<CreateIndicators />} />
      <Route path="/indications/more-details" element={<IndicationsDetails />} />
      <Route path="/sales" element={<Sales />} />
      <Route path="/sale/details" element={<SalesDetails />} />
      <Route path="/password" element={<ChangePassword />} />
    </Routes>
  </Layout>
)

export default PrivateRoutes
