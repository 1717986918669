import React from 'react'

import { Container } from './styles'

const Home: React.FC = () => {
  // const { user } = useContext(AuthContext)

  return (
    <Container>
    </Container>
  )
}

export default Home
