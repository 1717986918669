import React, { useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import {
  Div,
  InputContainer
} from '../../styles'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { toast } from 'react-toastify'

interface TriageFormProps {
  submit: boolean
  lead: any
  disable?: boolean
  handleFormData: (responseFromForm?: any) => void
}

const schemaTriage = yup.object().shape({
  who_is_decision_maker: yup.string().trim().required(),
  pat: yup.string().trim().required(),
  offers_some_benefit: yup.string().trim().required(),
  current_supplier: yup.string().trim().required()
}).required()

const TriageForm: React.FC<TriageFormProps> = (props: TriageFormProps) => {
  const formElement = useRef<HTMLFormElement | null>(null)
  const { register, handleSubmit, formState: { isDirty } } = useForm({
    defaultValues: {
      who_is_decision_maker: props.lead.steps.find((step: any) => step.name === 'Triagem').data.who_is_decision_maker ?? '',
      pat: props.lead.steps.find((step: any) => step.name === 'Triagem').data.pat ?? '',
      offers_some_benefit: props.lead.steps.find((step: any) => step.name === 'Triagem').data.offers_some_benefit ?? '',
      current_supplier: props.lead.steps.find((step: any) => step.name === 'Triagem').data.current_supplier ?? ''
    },
    resolver: yupResolver(schemaTriage)
  })

  const onSubmit = async (data: any, e: any): Promise<void> => {
    e.preventDefault()
    if (!(await schemaTriage.isValid(data))) {
      toast.error('Ocorreu um erro, verifique se os dados preenchidos estão corretos e tente novamente!')
      return
    }

    if (!isDirty) return

    const triageData = {
      who_is_decision_maker: data.who_is_decision_maker,
      pat: data.pat,
      offers_some_benefit: data.offers_some_benefit,
      current_supplier: data.current_supplier
    }

    const steps = props.lead?.steps as any[]

    const payload = {
      step_sequence: '1',
      step_sequence_id: steps.find(el => el?.sequence === parseInt('1'))?.id,
      data: triageData
    }

    props.handleFormData(payload)
  }

  useEffect(() => {
    if (props.submit && formElement.current) formElement.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
  }, [props.submit])

  return (
    <form ref={formElement} onSubmit={handleSubmit(onSubmit)}>
      <Div>
        <InputContainer>
        <label>Quem é o decisor?</label>
        <input
          disabled={props.disable}
          type='text'
          {...register('who_is_decision_maker')}
          name='who_is_decision_maker'
        />
        </InputContainer>
        <InputContainer>
        <label>Empresa é cadastrada no PAT?</label>
        <input
          disabled={props.disable}
          type='text'
          {...register('pat')}
          name='pat'
        />
        </InputContainer>
      </Div>
      <Div>
        <InputContainer>
          <label>Oferece algum benefício?</label>
          <input
            disabled={props.disable}
            type='text'
            {...register('offers_some_benefit')}
            name='offers_some_benefit'
          />
        </InputContainer>
        <InputContainer>
          <label>Atual fornecedor</label>
          <input
            disabled={props.disable}
            type='text'
            {...register('current_supplier')}
            name='current_supplier'
          />
        </InputContainer>
      </Div>
    </form>
  )
}

export default TriageForm
