import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100%;
`
export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.white};
  padding: 1rem;

  h2{
    color: ${theme.colors.black};
    margin-bottom: 30px;
  }
`
