import styled, { css } from 'styled-components'
import { theme } from '../../styles/theme'

export const Container = styled.div`
  width: 80%;
  height: 80vh;
  overflow-y: auto;

  @media (max-width: 600px) {
    height: 75vh;
    width: 80%;
  }
`
export const Step = styled.div`
  width: 100%;

  & + div {
    margin-top: 30px;
  }
`

export const Header = styled.div`
  width: 100%;
  height: 5vh;
  margin-bottom: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  p{
    font-weight: 600;
    color: ${theme.colors.black};
  }

`
export const Name = styled.p`
  font-size: 18px;
  line-height: 22px;
`

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + div {
    margin-top: 30px;
  }

  div {
    & + div {
      margin-left: 10px;
    }
  }

  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;

    & + div {
      margin-top: 0px;
    }
  }

`

export const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${theme.colors.black};
  margin-bottom: 15px;
`

interface PropsBox {
  lead?: boolean
}

export const Box = styled.div<PropsBox>`
  width: 100%;
  background: ${theme.colors.white};
  border: 1px solid ${theme.colors.border};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px;

  ${({ lead }) => lead && css`
    background: ${theme.colors.primary};

    input, select, label, p {
      color: ${theme.colors.white};
    }
  `}

`

export const ButtonContainer = styled.div`
  width: 100%;
`

export const Button = styled.button`
  width: 20%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${theme.colors.primary};
  border-radius: 4px;
  color: ${theme.colors.white};
  border: 0;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
`

export const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  color: ${theme.colors.black};
  width: 33.3%;
  @media (max-width: 600px) {
    width: 100%;
    margin-left: 5px;
  }

  div{
    display: flex;
    align-items: flex-start;
    justify-content: start;
    label{
      margin-left: 0.3rem;
    }
  }

`

export const Label = styled.p`
  font-size: 9px;
  line-height: 11px;
  margin-bottom: 10px;

  @media (max-width: 600px) {
    width: 100%;
    margin-left: 15px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 700;
  }
`

export const Description = styled.p`
font-size: 14px;
line-height: 17px;
@media (max-width: 600px) {
    margin-left: 15px;
    margin-bottom: 5px;
  }
`
