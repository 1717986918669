import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: scroll;
  height: 100%;
  max-height: 95%;
`

export const MainContent = styled.div`
  background-color: ${theme.colors.white};
  padding: 1rem;
  width: 100%;


  h2{
    color: ${theme.colors.black};
    margin-bottom: 30px;
    align-self: center;
  }

  form {
    align-self: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
  }
`

export const PersonalContent = styled.div`
  border: 0.1rem solid ${theme.colors.border};
  padding: 15px;
  border-radius: 4px;
  
  h3{
    color: ${theme.colors.black};
    margin-left: 15px;
    margin-bottom: 20px;
  }
`

export const PersonalDataRow = styled.div`
  display: inline-flex;
`
// export const PersonalDataLabel = styled.label`
//   font-size: 0.875rem;
//   margin-left: 15px;
//   color: ${theme.colors.black};
// `
// export const PersonalDataInfo = styled.p`
//   font-weight: bold;
//   font-size: 0.875rem;
//   margin-right: 15px;
//   color: ${theme.colors.black};
// `
export const ContactContent = styled.div`
  align-self: center;
  width: 100%;
  height: auto;
  border: 0.1rem solid ${theme.colors.border};
  border-radius: 4px;
  margin: 20px 0;
  padding: 15px;

  h3{
    color: ${theme.colors.black};
    margin-bottom: 20px;
    font-weight: bold;
  }
`

// export const ContactWrapper = styled.div`
//   border-bottom: 0.1rem solid ${theme.colors.border};
//   padding: 15px;
//   height: auto;

//   :last-child{
//     border-bottom: none;
//   }
//   `

// export const ContactDataRow = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;

//   @media only screen and (max-width: 425px) {
//     flex-direction: column;

//     & label, p {
//       margin: 0;
//     }
//   }
// `

// export const ContactInputRow = styled.div`
//   display: flex;
//   flex-direction: column;

//   input{
//     display: flex;
//     align-self: flex-start;
//     font-weight: bold;

//   }

// `

// export const ContactDataInput = styled.input`
//   border: none;
// `

// export const ButtonDataInput = styled.button`
//   border: none;
//   color: ${theme.colors.primary};
//   font-size: 14px;
//   background: none;
//   margin-top: 10px;
//   align-self: flex-start;
//   font-weight: bold;
// `

// export const ContactDataLabel = styled.label`
//   font-size: 0.875rem;
//   margin-left: 15px;
//   color: ${theme.colors.black};

// `
// export const ContactDataInfo = styled.p`
//   font-weight: bold;
//   font-size: 0.875rem;
//   margin-right: 15px;
//   color: ${theme.colors.black};
// `

export const BankWrapper = styled.div`
  border-bottom: 0.1rem solid ${theme.colors.border};
  padding: 15px;
  height: 100%;

  :last-child{
    border-bottom: none;
  }
  `

export const Div = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-around;

  & + div {
    margin-top: 30px;
    width: 100%;
  }

  div {
    & + div {
      margin-left: 10px;
    }
  }
  
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;

    div {
      margin-top: 10px;

      & + div {
        margin-left: 0;
      }
    }

    & + div {
      margin-top: 0px;
    }
  }
`

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;

  label {
    font-weight: 500;
    font-size: 12px;
    line-height: 11px;
    color: #212529;
    margin-bottom: 10px;
  }

  input, select {
    padding: 0 1.5rem;
    height: 3rem;
    width: 100%;
    border-radius: 10px;
    background: transparent;
    border: 1px solid ${theme.colors.border};

    font-weight: 400;
    font-size: 1.125rem;
  }

`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 4rem;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 2rem;

  @media (max-width: 990px) {
    margin: 0.5rem;
    flex-direction: column;
    gap: 1rem;
  }

`

export const Button = styled.button`
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  opacity: 1;
  background: ${theme.colors.primary};
  border-radius: 5px;
  color: ${theme.colors.white};
  border: 0;
  padding: 10px 5rem;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;

  @media (max-width: 600px) {
    width: 80%;
    margin: 0 auto;
  }

`
export const EditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-around;
  opacity: 1;
  background: ${theme.colors.yellow};
  border-radius: 5px;
  color: ${theme.colors.white};
  border: 0;
  padding: 10px 5rem;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;

  @media (max-width: 600px) {
    width: 80%;
    margin: 0 auto;
  }

`
