import api from '../../services/api';
import { toast } from 'react-toastify';
import { AuthContext } from '../../context/AuthContext';
import { BackLink } from '../../components';
import React, { useContext, useState } from 'react';
import {
	Container,
	Step,
	Title,
	Box,
	Div,
	Header,
	Name,
	ButtonContainer,
	Button,
	InputContainer,
	CheckBoxContainer,
	ButtonIcon,
	CheckBoxWrapper,
	CancelButtonIcon,
	InputSelectSchedule,
	TextAreaInput,
} from './styles';
import { Navigate, useNavigate } from 'react-router-dom';
import { maskCNPJ, maskPhone, maskReais } from '../../utils/masks';

interface Benefit {
	benefit: string;
	quantity: number | string;
	price: number | string;
}

interface Form {
	benefits: Benefit[];
	[fields: string]: any;
}

const CreateIndicators: React.FC = () => {
	const auth = useContext(AuthContext);
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [formValues, setFormValues] = useState<Form>({ benefits: [] });
	const [redirect, setRedirect] = useState<boolean>(false);
	const [buttonMessage, setButtonMessage] = useState<string>('Salvar');
	const [schedule, setSchedule] = useState<boolean>(false);

	const DATA = [
		{ text: 'VR Multi Alimentação', value: '11' },
		{ text: 'VR Multi Refeição', value: '12' },
		{ text: 'VR Multi Aux. VR + VA', value: '13' },
		{ text: 'VR Multi Aux. Home Office', value: '14' },
		{ text: 'VR Multi Mobilidade', value: '15' },
		{ text: 'VR Vale Transporte', value: '16' },
		{ text: 'VR Multi Aux. Alimentação', value: '17' },
		{ text: 'VR Multi Aux. Refeição', value: '18' },
		{ text: 'VR Gente - Pontomais', value: '19' },
	];

	const handleInputChange = (e: any): void => {
		const { name, value, checked, type } = e.target;
		setFormValues({
			...formValues,
			[name]: type === 'checkbox' ? checked : value,
		});

		if (
			buildMarkedFields('triage') &&
			(buildMarkedFields('lead') || buildMarkedFields('checkbox', formValues))
		) {
			setButtonMessage('Salvar e Enviar');
		} else {
			setButtonMessage('Salvar');
		}
	};

	const handleChangeFormArray = (
		field: 'quantity' | 'price' | 'benefit',
		value: string,
		index: number,
	): void => {
		const { benefits } = formValues;
		const el = benefits[index];

		benefits[index] = {
			...el,
			[field]: field === 'price' ? maskReais(value) : value,
		};

		setFormValues({ ...formValues, benefits: [...benefits] });

		if (
			buildMarkedFields('triage') &&
			(buildMarkedFields('lead') || buildMarkedFields('checkbox'))
		) {
			setButtonMessage('Salvar e Enviar');
		} else {
			setButtonMessage('Salvar');
		}
	};

	const handleAddBenefits = (): void => {
		setFormValues({
			...formValues,
			benefits: [
				...(formValues?.benefits || []),
				{ benefit: '1', quantity: '', price: '' },
			],
		});
	};

	const handleRemoveBenefits = (index: number): void => {
		formValues.benefits.splice(index, 1);
		setFormValues({ ...formValues });
	};

	const buildMarkedFields = (step: string, formUpdated?: any): boolean => {
		let isMarked = false;
		const arrayCheckboxes: any[] = [];

		switch (step) {
			case 'triage':
				for (const [key, value] of Object.entries(formValues)) {
					const fieldsMatch =
						key === 'who_is_decision_maker' ||
						key === 'pat' ||
						key === 'offers_some_benefit' ||
						key === 'current_supplier';

					if (fieldsMatch && value && String(value).length > 0) {
						isMarked = true;
					}
				}
				break;
			case 'lead':
				for (const [key, value] of Object.entries(formValues)) {
					const fieldsMatch =
						key === 'benefits' || key === 'quantity' || key === 'price';

					if (fieldsMatch && value && String(value).length > 0) {
						isMarked = true;
					}
				}
				break;
			case 'checkbox':
				for (const [key, value] of Object.entries(formUpdated)) {
					const fieldsMatch: any = [
						'nutrition_vr',
						'is_pae',
						'pharmacy_discount',
						'total_pass',
						'food_insurance',
						'marketplace_or_loyalty_vr',
						'marketplace_or_loyalty_vr',
						'rebate_or_value',
						'payment_term',
					];

					if (fieldsMatch.includes(key)) {
						arrayCheckboxes.push(value);
					}
				}

				arrayCheckboxes.some(el => el === true) && (isMarked = true);

				break;
		}

		return isMarked;
	};

	const handleSubmit = async (e: any): Promise<void> => {
		e.preventDefault();

		const [firstName, ...lastName] = formValues?.contact_rh?.split(' ');

		const formatToBoolean = (key: any): boolean => String(key) === 'true';

		const payload = {
			referenceId: auth?.user?.id,
			prospection: {
				social_reason: formValues?.social_reason,
				cnpj: formValues?.cnpj,
				contact: {
					first_name: firstName,
					last_name: lastName.join(' '),
					phone: formValues?.phone,
					professional_role: formValues?.function,
					email: formValues?.email,
				},
				marked: true,
			},
			triage: {
				who_is_decision_maker: formValues?.who_is_decision_maker,
				pat: formValues?.pat,
				offers_some_benefit: formValues?.offers_some_benefit,
				current_supplier: formValues?.current_supplier,
				marked: buildMarkedFields('triage'),
			},
			lead: {
				benefits: formValues?.benefits,
				nutrition_vr: formatToBoolean(formValues?.nutrition_vr),
				is_pae: formatToBoolean(formValues?.is_pae),
				pharmacy_discount: formatToBoolean(formValues?.pharmacy_discount),
				total_pass: formatToBoolean(formValues?.total_pass),
				food_insurance: formatToBoolean(formValues?.food_insurance),
				marketplace_or_loyalty_vr: formatToBoolean(
					formValues?.marketplace_or_loyalty_vr,
				),
				rebate_or_value: formatToBoolean(formValues?.rebate_or_value),
				payment_term: formatToBoolean(formValues?.payment_term),
				marked: buildMarkedFields('lead'),
			},
			schedule: {
				is_schedule: schedule,
				date: schedule ? formValues?.date : '',
				description: schedule ? formValues?.description : '',
			},
		};

		let validRequiredCheckBoxes: boolean = true;

		for (const [key, value] of Object.entries(payload.lead)) {
			if (!['marked'].includes(key)) {
				if (key === 'benefits' && value) {
					const benefitsArray = value as any[];

					const validArray = (benefitsArray || []).length;

					if (validArray) {
						validRequiredCheckBoxes = false;
					}
				}

				if (key !== 'benefits' && value) {
					validRequiredCheckBoxes = false;
				}
			}
		}

		if (buildMarkedFields('triage') && validRequiredCheckBoxes) {
			toast.warn('Ops! Preencha o campo de Benefícios.');
		}

		setLoading(true);

		await api
			.post('/indications', payload)
			.then(async (response: any) => {
				let successMessage: string = 'Indicação criada com sucesso!';

				if (buildMarkedFields('triage')) {
					successMessage = 'Indicação criada com sucesso!';
				}

				if (
					buildMarkedFields('lead') &&
					(buildMarkedFields('triage') || buildMarkedFields('checkbox'))
				) {
					await api
						.patch(
							`/indications/${String(response.data.data.id)}/qualify`,
							null,
						)
						.then(() => {
							successMessage = 'Indicação qualificada com sucesso!';
						});
					toast.success(successMessage);
					setLoading(false);
					setRedirect(true);
					return;
				}

				const { data: indicationsArr } = await api.get(
					`/indications/${String(auth?.user?.blueone_contact_id)}`,
				);
				const newIndication = indicationsArr.data.find(
					(indication: any) => indication.id === response.data.data.id,
				);

				navigate('/indications/more-details', {
					state: { data: newIndication },
					replace: true,
				});
				toast.success(successMessage);
				setLoading(false);
				setRedirect(false);
			})
			.catch(error => {
				setLoading(false);
				setRedirect(false);
				if (error.response.status === 400) {
					if (String(error.response.data.error).includes('exists')) {
						return toast.warning('Já existe uma indicação com esse CNPJ!');
					}

					if (String(error.response.data.error).includes('cnpj')) {
						return toast.warning('Digite um CNPJ válido!');
					}

					return toast.error(
						'Ocorreu um erro, verifique se os dados preenchidos estão corretos e tente novamente!',
					);
				}

				return toast.error(
					'Ocorreu um erro desconhecido ao criar a indicação, tente novamente em alguns momentos!',
				);
			});
	};

	const handleSchedule = (e: any): void => {
		e.preventDefault();
		const value = e.target.value;
		value === 'true' ? setSchedule(true) : setSchedule(false);
	};

	return (
		<Container>
			<BackLink link="/indications" />
			<Header>
				<div>
					<Name>Criar uma indicação</Name>
				</div>
			</Header>
			<form onSubmit={handleSubmit}>
				<Step>
					<Title>1. Prospecção</Title>
					<Box>
						<Div>
							<InputContainer>
								<label>Razão Social</label>
								<input
									type="text"
									name="social_reason"
									onChange={handleInputChange}
									value={formValues.social_reason || ''}
									required
								/>
							</InputContainer>
							<InputContainer>
								<label>Contato RH</label>
								<input
									type="text"
									name="contact_rh"
									onChange={handleInputChange}
									value={formValues.contact_rh || ''}
									required
								/>
							</InputContainer>
							<InputContainer>
								<label>Telefone</label>
								<input
									type="text"
									name="phone"
									onChange={handleInputChange}
									value={maskPhone(formValues.phone || '') || ''}
									required
								/>
							</InputContainer>
						</Div>
						<Div>
							<InputContainer>
								<label>CNPJ</label>
								<input
									maxLength={18}
									type="text"
									name="cnpj"
									onChange={handleInputChange}
									value={maskCNPJ(formValues.cnpj || '') || ''}
									required
								/>
							</InputContainer>
							<InputContainer>
								<label>Função</label>
								<input
									type="text"
									name="function"
									onChange={handleInputChange}
									value={formValues.function || ''}
									required
								/>
							</InputContainer>
							<InputContainer>
								<label>Email</label>
								<input
									type="text"
									name="email"
									onChange={handleInputChange}
									value={formValues.email || ''}
									required
								/>
							</InputContainer>
						</Div>
					</Box>
				</Step>
				<Step>
					<Title>2. Triagem</Title>
					<Box>
						<Div>
							<InputContainer>
								<label>Quem é o decisor?</label>
								<input
									name="who_is_decision_maker"
									value={formValues.who_is_decision_maker || ''}
									onChange={handleInputChange}
									required={buildMarkedFields('triage')}
								/>
							</InputContainer>
							<InputContainer>
								<label>Empresa é cadastrada no PAT?</label>
								<input
									name="pat"
									value={formValues.pat || ''}
									onChange={handleInputChange}
									required={buildMarkedFields('triage')}
								/>
							</InputContainer>
						</Div>
						<Div>
							<InputContainer>
								<label>Oferece algum benefício?</label>
								<input
									name="offers_some_benefit"
									value={formValues.offers_some_benefit || ''}
									onChange={handleInputChange}
									required={buildMarkedFields('triage')}
								/>
							</InputContainer>
							<InputContainer>
								<label>Atual fornecedor</label>
								<input
									name="current_supplier"
									value={formValues.current_supplier || ''}
									onChange={handleInputChange}
									required={buildMarkedFields('triage')}
								/>
							</InputContainer>
						</Div>
					</Box>
				</Step>
				<Step>
					<Title>3. Lead</Title>
					<ButtonIcon type="button" onClick={handleAddBenefits}>
						<p>+ INCLUIR BENEFÍCIOS</p>
					</ButtonIcon>
					<Box>
						{formValues.benefits.map((benefit: Benefit, index: number) => (
							<Div key={index}>
								<InputContainer>
									<label>Benefícios</label>
									<select
										name={`benefits.${index}`}
										onChange={event =>
											handleChangeFormArray(
												'benefit',
												event.target.value,
												index,
											)
										}
										required={
											!buildMarkedFields('lead') && !buildMarkedFields('triage')
										}
									>
										{DATA.map(({ text, value }, i) => (
											<option
												key={i.toString()}
												value={value || benefit.benefit}
											>
												{text}
											</option>
										))}
									</select>
								</InputContainer>
								<InputContainer>
									<label>Quantidade</label>
									<input
										value={formValues.benefits[index].quantity}
										onChange={event =>
											handleChangeFormArray(
												'quantity',
												event.target.value,
												index,
											)
										}
										type="number"
										required={
											!buildMarkedFields('lead') && !buildMarkedFields('triage')
										}
									/>
								</InputContainer>
								<InputContainer>
									<label>Valor</label>
									<div style={{ display: 'flex' }}>
										<input
											name={`price.${index}`}
											value={maskReais(benefit.price || '') || ''}
											onChange={event =>
												handleChangeFormArray(
													'price',
													event.target.value,
													index,
												)
											}
											required={
												!buildMarkedFields('lead') &&
												!buildMarkedFields('triage')
											}
										/>
										<CancelButtonIcon
											type="button"
											onClick={() => handleRemoveBenefits(index)}
										>
											<p>X</p>
										</CancelButtonIcon>
									</div>
								</InputContainer>
							</Div>
						))}
						<Div>
							<p>
								Atual fornecedor oferece algum benefício adicional? Taxa Zero?
								Desconto farmácia? Programa de assistência?
							</p>
						</Div>
						<CheckBoxWrapper>
							<CheckBoxContainer>
								<input
									type="checkbox"
									name="nutrition_vr"
									onChange={handleInputChange}
								/>
								<label>VR Nutrição</label>
							</CheckBoxContainer>
							<CheckBoxContainer>
								<input
									type="checkbox"
									name="is_pae"
									onChange={handleInputChange}
								/>
								<label>PAE</label>
							</CheckBoxContainer>
							<CheckBoxContainer>
								<input
									type="checkbox"
									name="pharmacy_discount"
									onChange={handleInputChange}
								/>
								<label>Desconto Farmácia</label>
							</CheckBoxContainer>
							<CheckBoxContainer>
								<input
									type="checkbox"
									name="total_pass"
									onChange={handleInputChange}
								/>
								<label>Total Pass</label>
							</CheckBoxContainer>
							<CheckBoxContainer>
								<input
									type="checkbox"
									name="food_insurance"
									onChange={handleInputChange}
								/>
								<label>Seguro Alimentação</label>
							</CheckBoxContainer>
							<CheckBoxContainer>
								<input
									type="checkbox"
									name="marketplace_or_loyalty_vr"
									onChange={handleInputChange}
								/>
								<label>VR Fidelidade</label>
							</CheckBoxContainer>
							<CheckBoxContainer>
								<input
									type="checkbox"
									name="rebate_or_value"
									onChange={handleInputChange}
								/>
								<label>Rebate/Valor</label>
							</CheckBoxContainer>
							<CheckBoxContainer>
								<input
									type="checkbox"
									name="payment_term"
									onChange={handleInputChange}
								/>
								<label>Prazo de Pagamento</label>
							</CheckBoxContainer>
						</CheckBoxWrapper>
					</Box>
				</Step>
				<Step>
					<Title>4. Agendamento</Title>
					<Div>
						<InputSelectSchedule>
							<label>Agendou reunião?</label>
							<select
								onChange={e => handleSchedule(e)}
								value={String(schedule)}
							>
								<option value="true">Sim</option>
								<option value="false">Não</option>
							</select>
						</InputSelectSchedule>
					</Div>
					{schedule && (
						<Box>
							<Div>
								<InputContainer>
									<label>Data do agendamento</label>
									<input
										type="datetime-local"
										name="date"
										value={formValues.date || ''}
										onChange={handleInputChange}
										required
									/>
								</InputContainer>
								<InputContainer>
									<label>Descrição</label>
									<TextAreaInput
										name="description"
										value={formValues.description || ''}
										onChange={handleInputChange}
										required
									/>
								</InputContainer>
							</Div>
						</Box>
					)}
				</Step>
				<ButtonContainer>
					<Button type="submit">
						{loading ? 'Salvando...' : buttonMessage}
					</Button>
					{redirect && <Navigate to={'/indications'} />}
				</ButtonContainer>
			</form>
		</Container>
	);
};

export default CreateIndicators;
