import React, { InputHTMLAttributes, SelectHTMLAttributes, useContext, useEffect, useState } from 'react'

import { Cards } from '../../components'
import { AuthContext } from '../../context/AuthContext'
import api from '../../services/api'
import { deleteData, getData, setDataObject } from '../../utils/localstorage'
import moment from 'moment'

import {
  Container,
  Header,
  Name,
  Subscribe,
  ContainerSales,
  FilterContainer,
  FilterWrapper

} from './styles'

interface filtersHistory {
  cnpj?: string
  date?: string
  status?: string
  protocol?: string
}

type filterKey = 'cnpj' | 'date' | 'status' | 'protocol'

const Sales: React.FC = () => {
  const auth = useContext(AuthContext)
  const [sales, setSales] = useState<any[]>([])
  const [newSales, setNewSales] = useState<any[]>([])
  const [inputFilter, setInputFilter] = useState<any>('')

  const LABELS_SALES = [
    'CNPJ',
    'Etapa',
    'Protocolo',
    'Data da última atualização',
    'Status Proposta'
  ]

  const $status = document.querySelector('[name="status"]') as SelectHTMLAttributes<any>
  const $date = document.querySelector('[name="date"]') as InputHTMLAttributes<any>
  const $cnpj = document.querySelector('[name="cnpj"]') as InputHTMLAttributes<any>
  const $protocol = document.querySelector('[name="protocol"]') as InputHTMLAttributes<any>

  const STORAGE_FORM_SALES_KEY = '@portalvr/form/sales'

  useEffect(() => {
    loadSales()
  }, [])

  useEffect(() => {
    handleFilter()
  }, [sales])

  const userName = `${auth.contact?.profile?.first_name as string} ${auth.contact?.profile?.last_name as string}`

  const loadSales = async (): Promise<void> => {
    try {
      const { data: { data: salesData } } = await api.get(`/sales/${String(auth?.user?.blueone_contact_id)}`)
      setSales(salesData)
    } catch (error) {
      console.log(error)
    }
  }

  const handleFilter = (): void => {
    const filter: filtersHistory = getData(STORAGE_FORM_SALES_KEY)
    if (!filter || sales.length === 0) return
    filterHistory(filter)
  }

  const handleInputChange = (e: any): void => {
    const getInput = e.target.value

    setInputFilter(getInput)
  }

  const filterCNPJ = (): void => {
    const oldSales = sales

    const formatedCNPJ = inputFilter.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')

    setDataObject(STORAGE_FORM_SALES_KEY, { cnpj: inputFilter })

    let newSaleFiltred = oldSales.filter((sale) => sale.data.prospection.cnpj === formatedCNPJ)

    if (newSaleFiltred.length === 0) {
      newSaleFiltred = oldSales.filter((sale) => (
        sale.data.prospection.cnpj === inputFilter
      ))
      if (newSaleFiltred.length === 0) {
        window.confirm('Dados não encontrados para esse filtro')
      }
    }

    setNewSales(newSaleFiltred)
  }

  const filterData = (): void => {
    const oldSales = sales

    setDataObject(STORAGE_FORM_SALES_KEY, { date: inputFilter })

    const newDate = oldSales.map((item) => {
      const formatedDate = moment(item.updated_at).format('YYYY-MM-DD')

      return {
        ...item,
        formatedDate
      }
    })

    const newSaleFiltred = newDate.filter((sale) => (
      sale.formatedDate === inputFilter
    ))

    if (newSaleFiltred.length === 0) {
      window.confirm('Dados não encontrados para esse filtro')
    }

    setNewSales(newSaleFiltred)
  }

  const filterStatusGN = (): void => {
    const oldSales = sales

    setDataObject(STORAGE_FORM_SALES_KEY, { status: inputFilter })

    const newSaleFiltred = oldSales.filter((sale) => (
      sale.data.status_gn === inputFilter
    ))

    if (newSaleFiltred.length === 0) {
      window.confirm('Dados não encontrados para esse filtro')
      deleteData(STORAGE_FORM_SALES_KEY)
    }

    setNewSales(newSaleFiltred)
  }

  const filterProtocol = (): void => {
    const oldSales = sales

    setDataObject(STORAGE_FORM_SALES_KEY, { protocol: inputFilter })

    const newSaleFiltred = oldSales.filter((sale) => (
      sale.data.protocol === inputFilter
    ))

    if (newSaleFiltred.length === 0) {
      window.confirm('Dados não encontrados para esse filtro')
    }

    setNewSales(newSaleFiltred)
  }

  const filterHistory = (filterHistory: filtersHistory): void => {
    const oldSales = sales

    const byCNPJ = (cnpj: string): void => {
      let newSaleFiltred = oldSales.filter((sale) => (
        sale.data.prospection.cnpj === cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      ))

      if (newSaleFiltred.length === 0) {
        newSaleFiltred = oldSales.filter((sale) => (
          sale.data.prospection.cnpj === cnpj
        ))
        if (newSaleFiltred.length === 0) {
          window.confirm('Dados não encontrados para esse filtro')
        }
      }
      $cnpj.value = cnpj
      setNewSales(newSaleFiltred)
    }

    const byDate = (date: string): void => {
      const newDate = oldSales.map((item) => {
        const formatedDate = moment(item.updated_at).format('YYYY-MM-DD')

        return {
          ...item,
          formatedDate
        }
      })

      const newSaleFiltred = newDate.filter((sale) => (
        sale.formatedDate === date
      ))

      if (newSaleFiltred.length === 0) {
        window.confirm('Dados não encontrados para esse filtro')
      }
      $date.value = date
      setNewSales(newSaleFiltred)
    }

    const byStatus = (status: string): void => {
      const newSaleFiltred = oldSales.filter((sale) => (
        sale.data.status_gn === status
      ))

      if (newSaleFiltred.length === 0) {
        window.confirm('Dados não encontrados para esse filtro')
        deleteData(STORAGE_FORM_SALES_KEY)
      }

      $status.value = status
      setNewSales(newSaleFiltred)
    }

    const byProtocol = (protocol: string): void => {
      const newSaleFiltred = oldSales.filter((sale) => (
        sale.data.protocol === protocol
      ))

      if (newSaleFiltred.length === 0) {
        window.confirm('Dados não encontrados para esse filtro')
      }
      $protocol.value = protocol
      setNewSales(newSaleFiltred)
    }

    const filterStoraged = {
      cnpj: byCNPJ,
      date: byDate,
      status: byStatus,
      protocol: byProtocol
    }

    const filterType = Object.keys(filterHistory)[0] as filterKey
    const filterValue = Object.values(filterHistory)[0]
    filterStoraged[filterType](filterValue)
  }

  const cleanFilter = (e: any): void => {
    e.preventDefault()
    deleteData(STORAGE_FORM_SALES_KEY)
    setNewSales([])
    $status.value = ''
    $date.value = ''
    $cnpj.value = ''
    $protocol.value = ''
  }

  return (
    <Container>
      <Header>
        <div>
          <Name>Olá, {userName}!</Name>
          <Subscribe>Suas vendas</Subscribe>
        </div>

        <button onClick={(event) => cleanFilter(event)}>Limpar Filtros</button>
      </Header>
      <FilterContainer>
        <FilterWrapper>
          <div>
            <label>Data</label>
            <input type='date' name='date' onChange={handleInputChange}/>
            <button type='button' onClick={filterData}>Pesquisar</button>
          </div>
          <div>
            <label>CNPJ</label>
            <input type='text' name='cnpj' placeholder='Digite o CNPJ' onChange={handleInputChange} ></input>
            <button type='button' onClick={filterCNPJ}>Pesquisar</button>
          </div>
        </FilterWrapper>
        <FilterWrapper>
          <div>
            <label>Protocolo</label>
            <input type='text' name='protocol' placeholder='Digite o Protocolo' onChange={handleInputChange}></input>
            <button type='button' onClick={filterProtocol}>Pesquisar</button>
          </div>
          <div>
            <label>Status Proposta</label>
            <select name='status' onChange={handleInputChange}>
              <option value="">Selecione</option>
              <option value="Contrato Ativo" >Contrato Ativo</option>
              <option value="Contrato Creditado">Contrato Creditado</option>
            </select>
            <button type='button' onClick={filterStatusGN}>Pesquisar</button>
          </div>
        </FilterWrapper>
      </FilterContainer>
      <ContainerSales>
        <Cards labels={LABELS_SALES} indicationsData={newSales.length === 0 ? sales : newSales} sale/>
      </ContainerSales>
    </Container>
  )
}

export default Sales
