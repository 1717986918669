import { Container } from './styles'

const Training: React.FC = () => {
  return (
    <Container>
      <iframe src="https://drive.google.com/embeddedfolderview?id=1p3MwPC_9ukyM0hkJbWB2XvTro2fDljzJ#list"
        style={{ width: '800px', height: '800px', border: '0' }}>
        <a href='https://drive.google.com/drive/folders/12EG0FkUmcCD4XuvkFAslKJHxToHXuPz6' target={'_self'}>
          <button>Pasta</button>
        </a>
      </iframe>

    </Container>
  )
}
export default Training
