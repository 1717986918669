export const getData = (key: string): any => {
  const data = localStorage.getItem(key)
  if (!data) return null
  return JSON.parse(data)
}

export const setDataObject = (key: string, data: any): void => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const setDataValue = (key: string, data: any): void => {
  localStorage.setItem(key, data)
}

export const deleteData = (key: string): void => {
  localStorage.removeItem(key)
}
