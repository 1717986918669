import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'

import { IMAGES } from '../../assets'
import { Container, MenuBar, Profile, Button, MenuHamburguer, BankAccount } from './styles'
import { theme } from '../../styles/theme'

const Header: React.FC = () => {
  const { isAuthenticated, contact, logout } = useContext(AuthContext)

  const getContactNameInitials = (): string => {
    const contactProfile = contact?.profile

    if (!contactProfile) {
      return 'U'
    }

    const firstNameFirstLetter: string = contactProfile.first_name[0]
    const lastNameFirstLetter: string = contactProfile.last_name[0]
    return `${firstNameFirstLetter}${lastNameFirstLetter}`
  }

  const [checked, setChecked] = useState(false)

  return (
    <Container loggedIn={isAuthenticated}>
      <Link to="/">
        <img className="logo-vr" src={IMAGES.LOGO} />
      </Link>

      {isAuthenticated && (
          <>
            <MenuHamburguer checked={checked}>
              <nav className="navbar">
                <div className="navbar-container container">
                    <input type="checkbox" checked={checked} onChange={() => setChecked(!checked)}/>
                    <div className="hamburger-lines">
                      <span className="line line1"></span>
                      <span className="line line2"></span>
                      <span className="line line3"></span>
                    </div>
                    <ul className="menu-items">
                      <li><img src={IMAGES.TRAINING}/><Link to='/training'>Treinamentos</Link></li>
                      <li><img src={IMAGES.DASHBOARD}/><Link to='/dashboard' onClick={() => setChecked(false)}>Dashboard</Link></li>
                      <li><img src={IMAGES.REGULATION}/><Link to='/regulations' onClick={() => setChecked(false)}>Regulamentos</Link></li>
                      <li><img src={IMAGES.PROPOSAL}/><Link to='/sales' onClick={() => setChecked(false)}>Propostas</Link></li>
                      <li><img src={IMAGES.INDICATION}/><Link to='/indications' onClick={() => setChecked(false)}>Indicações</Link></li>
                      <li><img src={IMAGES.PROFILE}/><Link to='/profile' onClick={() => setChecked(false)}>Meu Perfil</Link></li>
                      <li><Link style={{ color: 'orange' }}to='/password' onClick={() => setChecked(false)}>Alterar senha</Link></li>
                      <li><img src={IMAGES.EXIT}/><Link to="/" onClick={logout}>Sair</Link></li>
                    </ul>
                </div>
              </nav>
            </MenuHamburguer>
            <MenuBar>
              <BankAccount>
                <p>Banco: {contact?.profile?.data?.bank}</p>
                <p>Agência: {contact?.profile?.data?.agency}</p>
                <p>Conta: {contact?.profile?.data?.accountNumber}</p>
              </BankAccount>
              <Link to='/training'>Treinamentos</Link>
              <Link to='/dashboard'>Dashboard</Link>
              <Link to='/regulations'>Regulamentos</Link>
              <Link to='/sales'>
                <Button backgroundColor={'secondary'} style={{ backgroundColor: '#008C15' }} disabled={false}>
                  Propostas <br />Fechadas
                </Button>
              </Link>
              <Link to='/indications'>
                <Button backgroundColor={theme.colors.yellow} style={{ backgroundColor: '#FFC600' }} disabled={false}>
                  <div className="logo-indicacoes"><img src={IMAGES.CURRENCY} /></div>
                  Indicações
                </Button>
              </Link>
              <Link to="/">
                <Profile>
                  <div className="profile-picture">
                    {getContactNameInitials()}
                  </div>

                  <img src={IMAGES.ARROW_DOWN} />
                  <span className="dropdown-content">
                    <Link to="/">
                      <li className="dropdown-item">
                        Meu perfil
                      </li>
                    </Link>
                    <Link to="/password">
                      <li className="dropdown-item">
                        Alterar senha
                      </li>
                    </Link>
                    <Link to="/" onClick={logout}>
                      <li className="dropdown-item">
                        Sair
                      </li>
                    </Link>
                  </span>
                </Profile>
              </Link>
            </MenuBar>
          </>
      )}
    </Container>
  )
}

export default Header
