import React, { useContext, useEffect, useRef, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import {
  BankWrapper,
  Button,
  ButtonContainer,
  EditButton,
  Container, Div, InputContainer, MainContent, PersonalContent, ContactContent
} from './styles'
import { toast } from 'react-toastify'
import api from '../../services/api'

const schemaPersonalInformation = yup.object().shape({
  first_name: yup.string().trim().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  last_name: yup.string().trim().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  cpf: yup.string().trim().required().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  person_type: yup.string().trim().required().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  name: yup.string().trim().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  responsible_name: yup.string().trim().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  birthday_at: yup.string().trim().required().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  bank: yup.string().trim().required().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  agency: yup.string().trim().required().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  accountNumber: yup.string().trim().required().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  zipcode: yup.string().trim().required().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  address_street: yup.string().trim().required().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  address_number: yup.string().trim().required().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  neighborhood: yup.string().trim().required().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  city: yup.string().trim().required().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  state: yup.string().trim().required().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  email: yup.string().trim().required().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!'),
  phone: yup.string().trim().required().typeError('Ocorreu um erro, verifique se o campo foi preenchido corretamente!')
}).required()

const PhoneProfile = (value: any): any => {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '+$1 $2')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})(\d+?)$/, '$1')
  }
}

const PhoneToPayload = (value: any): any => {
  if (value) {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '+55$1$2')
      .replace(/(\d{2})(\d)/, '$1 $2')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})(\d+?)$/, '$1')
  }
}

const Regulations: React.FC = () => {
  const { contact, user, recoverUser } = useContext(AuthContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [edit, setEdit] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<any>({})
  const formElement = useRef<HTMLFormElement | null>(null)
  const [sendEmail, setSendEmail] = useState<string[]>([])

  const [type, setType] = useState('pf')

  const { register, handleSubmit, setValue, getValues, formState: { isDirty }, getFieldState } = useForm({
    defaultValues: {
      person_type: 'pj',
      responsible_name: ' ',
      name: '',
      first_name: '',
      last_name: '',
      cpf: '',
      birthday_at: new Date().toISOString().split('T')[0],
      bank: '',
      agency: '',
      accountNumber: '',
      zipcode: '',
      address_street: '',
      address_number: '',
      neighborhood: '',
      city: '',
      state: '',
      email: '',
      phone: ''
    },
    resolver: yupResolver(schemaPersonalInformation)
  })

  const onSubmit = async (data: any, e: any): Promise<void> => {
    e.preventDefault()

    const stateFistName = getFieldState('first_name').isTouched
    const stateLastName = getFieldState('last_name').isTouched
    const stateCpf = getFieldState('cpf').isTouched
    const stateBank = getFieldState('bank').isTouched
    const stateAgency = getFieldState('agency').isTouched
    const stateAccountNumber = getFieldState('accountNumber').isTouched

    if (stateFistName) sendEmail.push('Nome ') // Deixar o espaço no final para separar os nomes
    if (stateLastName) sendEmail.push('Sobrenome ') // Deixar o espaço no final para separar os nomes
    if (stateCpf) sendEmail.push('CPF ') // Deixar o espaço no final para separar os nomes
    if (stateBank) sendEmail.push('Banco ') // Deixar o espaço no final para separar os nomes
    if (stateAgency) sendEmail.push('Agência ') // Deixar o espaço no final para separar os nomes
    if (stateAccountNumber) sendEmail.push('Número da conta ') // Deixar o espaço no final para separar os nomes

    if (!(await schemaPersonalInformation.isValid(data))) {
      toast.error('Ocorreu um erro, verifique se os campos foram preenchidos corretamente!')
      return
    }

    if (!isDirty) {
      toast.warn('Nenhum campo foi alterado!')
      setLoading(false)
      setEdit(false)
      return
    }

    const phoneFormatedToPayload = PhoneToPayload(data.phone)
    const birthdateFormattedToPayload = new Date(data.birthday_at).toISOString()

    const payload = {
      first_name: data.first_name,
      last_name: data.last_name,
      cpf: data.cpf,
      birthday_at: birthdateFormattedToPayload,
      bank: data.bank,
      agency: data.agency,
      accountNumber: data.accountNumber,
      zipcode: data.zipcode,
      address_street: data.address_street,
      address_number: data.address_number,
      neighborhood: data.neighborhood,
      city: data.city,
      state: data.state,
      category_id: contact?.category?.id ?? contact?.profile?.data?.data?.category_id,
      partner_pocket: contact?.partner?.operator_id ?? contact?.profile?.data?.partner_pocket ?? contact?.profile?.data?.data?.partner_pocket,
      phone: phoneFormatedToPayload,
      email: data.email,
      changeFieldsToSendEmail: sendEmail,
      person_type: type,
      responsible_name: data.responsible_name
    }

    if (type === 'pj') {
      const [firstName, ...lastNamePieces] = data.name.trim().split(' ')
      const lastName = lastNamePieces.join(' ')
      payload.first_name = firstName
      payload.last_name = lastName || ' '
    }

    if (type === 'pf') {
      payload.responsible_name = ' '
    }

    setLoading(true)

    await api.patch(`/users/${String(user?.blueone_contact_id)}/contact/bank`,
      { ...payload }
    ).then((response: { data: any }) => {
      toast.success('Alteração feita com sucesso!')
      localStorage.setItem('@portalvr/form', JSON.stringify(true))
      recoverUser()
      setLoading(false)
      setEdit(false)
    }).catch(error => {
      setLoading(false)
      if (error.response.status === 400) {
        console.log('error.response.data', error.response.data)
        return toast.error(
          'Ocorreu um erro, verifique se os dados preenchidos estão corretos e tente novamente!'
        )
      }
      return toast.error(
        'Ocorreu um erro desconhecido, tente novamente em alguns momentos!'
      )
    })

    setLoading(false)
    setSendEmail([])
  }

  const onInit = (): void => {
    if (contact) {
      const birthdateFormatted = new Date(contact?.profile?.birthday_at).toISOString().split('T')[0]

      const phoneFormated = PhoneProfile(contact?.phones?.[0]?.phone)

      setValue('first_name', contact?.profile?.first_name ?? '')
      setValue('last_name', contact?.profile?.last_name ?? '')
      setValue('name', `${contact?.profile?.first_name ?? ''} ${contact?.profile?.last_name ?? ''}`)
      setValue('person_type', contact?.profile?.data?.person_type ?? 'pf')
      setValue('responsible_name', contact?.profile?.data?.responsible_name ?? '')
      setValue('cpf', contact?.profile?.cpf ?? '')
      setValue('birthday_at', birthdateFormatted ?? new Date().toISOString().split('T')[0])
      setValue('bank', contact?.profile?.data?.bank ?? '')
      setValue('agency', contact?.profile?.data?.agency ?? '')
      setValue('accountNumber', contact?.profile?.data?.accountNumber ?? '')
      setValue('zipcode', contact?.addresses?.[0]?.zipcode ?? '')
      setValue('address_street', contact?.addresses?.[0]?.address_street ?? '')
      setValue('address_number', contact?.addresses?.[0]?.address_number ?? '')
      setValue('neighborhood', contact?.addresses?.[0]?.neighborhood ?? '')
      setValue('city', contact?.addresses?.[0]?.city?.name ?? '')
      setValue('state', contact?.addresses?.[0]?.city?.state?.name ?? '')
      setValue('email', contact?.emails?.[0]?.email ?? '')
      setValue('phone', phoneFormated ?? '')

      setType(contact?.profile?.data?.person_type ?? 'pf')

      setInitialValues(getValues())
    }
  }

  const verifyFormFieldsEmpty = (): void => {
    if (initialValues) {
      for (const value in initialValues) {
        if (initialValues[value] === '' ||
          initialValues[value] === undefined ||
          initialValues[value] === null) {
          localStorage.setItem('@portalvr/form', JSON.stringify(false))
          return
        }
        localStorage.setItem('@portalvr/form', JSON.stringify(true))
      }
    }
  }

  useEffect(() => {
    onInit()
  }, [contact])

  useEffect(() => {
    verifyFormFieldsEmpty()
  }, [initialValues])

  return (
    <Container>
      <MainContent>
        <div>
          <form ref={formElement} onSubmit={handleSubmit(onSubmit)}>
            <h2>Meus dados</h2>
            <PersonalContent>
              <BankWrapper>
                <ButtonContainer>
                  <EditButton type='button' onClick={() => setEdit(!edit)}>
                    {!edit ? 'Editar' : 'Cancelar'}
                  </EditButton>
                  <Button disabled={!edit}>
                    {loading ? 'Salvando...' : 'Salvar'}
                  </Button>
                </ButtonContainer>
                <ContactContent>
                  <h3>Informações pessoais</h3>
                  <Div>
                    <InputContainer>
                      <label>Tipo de pessoa</label>
                      <select
                        disabled={!edit}
                        {...register('person_type')}
                        onChange={e => setType(e.target.value)}
                      >
                        <option value={'pf'}>Pessoa Física</option>
                        <option value={'pj'}>Pessoa Jurídica</option>
                      </select>
                    </InputContainer>
                  </Div>

                  {type !== 'pj' &&
                    <>
                      <Div>
                        <InputContainer>
                          <label>Nome</label>
                          <input
                            readOnly={!edit}
                            type='text'
                            {...register('first_name')}
                          />
                        </InputContainer>
                      </Div>
                      <Div>
                        <InputContainer>
                          <label>Sobrenome</label>
                          <input
                            readOnly={!edit}
                            type='text'
                            {...register('last_name')}
                          />
                        </InputContainer>
                      </Div></>}
                  {type === 'pj' &&
                    <>
                      <Div>
                        <InputContainer>
                          <label>Nome</label>
                          <input
                            readOnly={!edit}
                            type='text'
                            {...register('name')}
                          />
                        </InputContainer>
                      </Div></>}
                  <Div>
                    <InputContainer>
                      <label>{type !== 'pj' ? 'CPF' : 'CNPJ'}</label>
                      <input
                        readOnly={!edit}
                        type='text'
                        {...register('cpf')}
                      />
                    </InputContainer>
                  </Div>

                  {type === 'pj' &&
                      <Div>
                        <InputContainer>
                          <label>Nome do responsável</label>
                          <input
                            readOnly={!edit}
                            type='text'
                            {...register('responsible_name')}
                          />
                        </InputContainer>
                      </Div>}
                  <Div>
                    <InputContainer>
                      <label>Data Nascimento</label>
                      <input
                        readOnly={!edit}
                        type='date'
                        {...register('birthday_at')}
                      />
                    </InputContainer>
                  </Div>
                </ContactContent>
                <ContactContent>
                  <h3>Informações de contato</h3>
                  <Div>
                    <InputContainer>
                      <label>Email</label>
                      <input
                        readOnly={!edit}
                        type='text'
                        {...register('email')}
                      />
                    </InputContainer>
                  </Div>
                  <Div>
                    <InputContainer>
                      <label>Telefone</label>
                      <input
                        placeholder='(00) 00000-0000'
                        readOnly={!edit}
                        type='text'
                        {...register('phone')}
                      />
                    </InputContainer>
                  </Div>
                </ContactContent>
                <ContactContent>
                  <h3>Endereço</h3>
                  <Div>
                    <InputContainer>
                      <label>CEP</label>
                      <input
                        readOnly={!edit}
                        type='text'
                        {...register('zipcode')}
                      />
                    </InputContainer>
                  </Div>
                  <Div>
                    <InputContainer>
                      <label>Rua</label>
                      <input
                        readOnly={!edit}
                        type='text'
                        {...register('address_street')}
                      />
                    </InputContainer>
                  </Div>
                  <Div>
                    <InputContainer>
                      <label>Número</label>
                      <input
                        readOnly={!edit}
                        type='text'
                        {...register('address_number')}
                      />
                    </InputContainer>
                  </Div>
                  <Div>
                    <InputContainer>
                      <label>Bairro</label>
                      <input
                        readOnly={!edit}
                        type='text'
                        {...register('neighborhood')}
                      />
                    </InputContainer>
                  </Div>
                  <Div>
                    <InputContainer>
                      <label>Cidade</label>
                      <input
                        readOnly={!edit}
                        type='text'
                        {...register('city')}
                      />
                    </InputContainer>
                  </Div>
                  <Div>
                    <InputContainer>
                      <label>Estado</label>
                      <input
                        readOnly={!edit}
                        type='text'
                        {...register('state')}
                      />
                    </InputContainer>
                  </Div>
                </ContactContent>
                <ContactContent>
                  <h3>Informações bancárias</h3>
                  <Div>
                    <InputContainer>
                      <label>Banco</label>
                      <input
                        readOnly={!edit}
                        type='text'
                        {...register('bank')}
                      />
                    </InputContainer>
                  </Div>
                  <Div>
                    <InputContainer>
                      <label>Agência</label>
                      <input
                        readOnly={!edit}
                        type='text'
                        {...register('agency')}
                      />
                    </InputContainer>
                  </Div>
                  <Div>
                    <InputContainer>
                      <label>Conta</label>
                      <input
                        readOnly={!edit}
                        type='text'
                        {...register('accountNumber')}
                      />
                    </InputContainer>
                  </Div>
                </ContactContent>
              </BankWrapper>
            </PersonalContent>
          </form>
        </div>
      </MainContent>
    </Container>
  )
}

export default Regulations
