import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (max-width: 425px) {
    overflow: auto;
  }
`

export const ContainerSales = styled.div`
  width: 100%;
  height: auto;
  box-sizing: border-box;
  overflow: auto;
  padding-bottom: 8rem;

  @media only screen and (max-width: 425px) {
    overflow: visible;
  }

`
export const Header = styled.div`
  width: 80%;
  height: 150px;
  padding: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  button{
  background: ${theme.colors.yellow};
  border-radius: 4px;
  min-width: 128px;
  height: 30px;
  color: ${theme.colors.white};
  border: none;
  padding: .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;

  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  }

  p{
    font-weight: 600;
    color: ${theme.colors.black};
     
    & + p {
      margin-top: 15px;
    }
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 15px;
  }
`

export const Name = styled.p`
  font-size: 18px;
  line-height: 22px;
`

export const Subscribe = styled.p`
  font-size: 20px;
  line-height: 24px;
`

export const Icons = styled.div`
  button {
    margin: 10px;
    height: 30px;
    width: 30px;
    background-repeat: no-repeat;
    background-position: center center;
    border: 0;
    cursor: pointer;
  }
`

interface PropsButton {
  bgImage: string
}

export const ButtonIcon = styled.button<PropsButton>`
  background: url(${(props) => props.bgImage});
`
export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;

  width: 100%;

`
export const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 3rem;

  div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 400px;
    margin: 0 auto;
  }

  input{
    padding: 0 1.5rem;
    height: 1.5rem;
    width: 100%;
    border-radius: 10px;
    background: transparent;
    border: 1px solid ${theme.colors.border};

    font-weight: 400;
    font-size: 0.75em;
  }

  select{
    padding: 0 1.5rem;
    height: 2rem;
    width: 100%;
    border-radius: 10px;
    background: transparent;
    border: 1px solid ${theme.colors.border};

    font-weight: 400;
    font-size: 0.75em;
  }

  label {
    font-weight: 500;
    font-size: 14px;
    color: #212529;
    margin-right: 0.5rem;
    white-space: nowrap
  }

  button{
    background: ${theme.colors.primary};
    border-radius: 4px;
    min-width: 128px;
    height: 30px;
    color: ${theme.colors.white};
    border: none;
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0.5rem;

    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    height: auto;

    div{
      width: 80%;
      
      margin: 0.75rem auto;
    }

    input {
      margin: 0.5rem auto;
      padding: 0.75rem 1.5rem;
    }

    select {
      margin: 0.5rem auto;
      padding: 0 1.5rem;
    }
  }

  @media only screen and (max-width: 425px) {
    flex-direction: column;
    height: auto;

    div{
      width: 80%;
      flex-direction: column;
      margin: 0.75rem auto;
    }

    input {
      margin: 0.5rem auto;
      padding: 0.75rem 1.5rem;
    }

    select {
      margin: 0.5rem auto;
      padding: 0 1.5rem;
    }
  }
`
