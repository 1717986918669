import styled from 'styled-components'
import { theme } from '../styles/theme'

export const Container = styled.div`
  display: grid;
  grid-template-rows: 75px 1fr;
  grid-template-areas:
      'topmenu topmenu'
      'body body';
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: absolute;
  -o-transition: grid-template-columns 0.5s;
  -moz-transition: grid-template-columns 0.5s;
  -webkit-transition: grid-template-columns 0.5s;
  transition: grid-template-columns 0.5s;
`

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

export const TopMenu = styled.div`
  grid-area: topmenu;
`

export const MainContent = styled.div`
  grid-area: body;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background: ${theme.colors.white};
  width: 70%;
  height: 100%;
  
  @media only screen and (max-width: 425px){
    width: 100%;
  }

  @media only screen and (min-width: 426px) and (max-width: 768px) {
    width: 90%;
  }
`
export const Content = styled.div`
  max-width: 55%;
  width: 100%;
  height: 100%;
`
