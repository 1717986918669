const environmentSwitcher = {
  production: {
    apiUrl: 'https://vr-portal-api.prd.b1app.com.br/api',
    chatApiUrl: 'https://apichat.b1app.com.br',
    chatWsUrl: 'wss://wschat.b1app.com.br',
    b1Organization: '837ef30c-9da7-47e7-afaa-cd733ebdd5af'
  },
  staging: {
    apiUrl: 'https://vr-portal-api.prd.b1app.com.br/api',
    chatApiUrl: 'https://apichat.b1app.com.br',
    chatWsUrl: 'wss://wschat.b1app.com.br',
    b1Organization: '837ef30c-9da7-47e7-afaa-cd733ebdd5af'
  }
}

export const environment =
 process.env?.REACT_APP_NODE_ENV === 'production'
   ? environmentSwitcher.production
   : environmentSwitcher.staging
