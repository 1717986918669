import React, { FormEvent, useContext, useState } from 'react'
import api from '../../services/api'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import { Container, Form, Row } from './styles'
import { AuthContext } from '../../context/AuthContext'

const Password: React.FC = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()

  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')
  const [verified, setVerified] = useState(false)
  const [loading, setLoading] = useState(false)
  const [validatePassword, setValidatePassword] = useState({
    upper: false,
    lower: false,
    number: false,
    special: false
  })

  function secureText (event: string): void {
    const regexUppercase = /^(?=.*[A-Z]).+$/
    const regexLowercase = /^(?=.*[a-z]).+$/
    const regexNumber = /^(?=.*[0-9]).+$/
    const regexSpecialCharacters = /^(?=.*[@$!%*#?&]).+$/

    setValidatePassword({
      upper: regexUppercase.test(event),
      lower: regexLowercase.test(event),
      number: regexNumber.test(event),
      special: regexSpecialCharacters.test(event)
    })
    setPassword(event)
  }

  async function handleSubmit (event: FormEvent): Promise<void> {
    event.preventDefault()

    const data = {
      password,
      password_confirmation: password,
      current_password: code
    }

    try {
      setLoading(true)

      const userId: string = auth?.user?.id ?? ''

      await api.patch(`/users/${userId}/change-password`, data)
      toast.success('Sucesso! Senha alterada com sucesso!')
      setLoading(false)
      navigate('/')
    } catch (error: any) {
      setLoading(false)
      if (error?.response?.data?.error) {
        toast.error(error?.response?.data?.error)
      } else {
        toast.error('Ops! Tente novamente mais tarde')
      }
    }
  }

  return (
    <Container>
        <Form onSubmit={handleSubmit}>
          <h2>Alterar senha</h2>

          <input
            placeholder='Senha atual'
            type='password'
            value={code}
            style={{ marginBottom: 15 }}
            onChange={event => setCode(event.target.value)}
          />

          <input
            placeholder='Senha'
            type='password'
            onChange={event => secureText(event.target.value)}
          />
          <Row>
            <span style={validatePassword.upper ? { backgroundColor: '#00A413' } : { backgroundColor: '#c4c4c4' }}>
              1 letra maiúscula
            </span>
            <span style={validatePassword.lower ? { backgroundColor: '#00A413' } : { backgroundColor: '#c4c4c4' }}>
              1 letra minúscula
            </span>
            <span style={validatePassword.special ? { backgroundColor: '#00A413' } : { backgroundColor: '#c4c4c4' }}>
              1 caractere especial
            </span>
            <span style={validatePassword.number ? { backgroundColor: '#00A413' } : { backgroundColor: '#c4c4c4' }}>
              1 número
            </span>
          </Row>

          <input
            placeholder='Confirmar senha'
            type='password'
            onChange={event => event.target.value === password ? setVerified(true) : setVerified(false)}
          />

          <button type='submit' disabled={!verified} style={!verified ? { opacity: 0.5 } : { opacity: 1 }}>
            {loading ? 'Alterando...' : 'Alterar'}
          </button>
        </Form>
    </Container>
  )
}

export default Password
