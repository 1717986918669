import React, { useContext } from 'react'

import PublicRoutes from './PublicRoutes'
import PrivateRoutes from './PrivateRoutes'
import { AuthContext } from '../context/AuthContext'

const Routes: React.FC = () => {
  const { isAuthenticated } = useContext(AuthContext)

  return isAuthenticated ? <PrivateRoutes /> : <PublicRoutes />
}

export default Routes
