import React, { FormEvent, useContext, useState } from 'react'
import { Link } from 'react-router-dom'

import { AuthContext } from '../../context/AuthContext'
import { validateEmail } from '../../utils/regex'

import { Container, Form } from './styles'

const SingIn: React.FC = () => {
  const { signIn } = useContext(AuthContext)

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')

  async function handleSubmit (event: FormEvent): Promise<void> {
    event.preventDefault()

    if (!validateEmail.test(email)) {
      return
    }
    setLoading(true)
    const data = {
      email,
      password
    }

    await signIn(data)
    setLoading(false)
  }

  return (
    <Container>
        <Form onSubmit={handleSubmit}>
          <h2>Para continuar, informe seus dados:</h2>

          <input
            placeholder='E-mail'
            type='email'
            value={email}
            onChange={event => setEmail(event.target.value)}
          />
            <input
            placeholder='Senha'
            type='password'
            value={password}
            onChange={event => setPassword(event.target.value)}
          />

          <button disabled={loading} type='submit'>
            {loading ? 'Carregando...' : 'Continuar'}
          </button>

          <span className="register-message">
            Ainda não está registrado? <Link to="register">Crie sua conta</Link>
          </span>
          <span className="register-message">
            Esqueceu a sua senha? <Link to="forgot">Recupere aqui</Link>
          </span>

        </Form>
    </Container>
  )
}

export default SingIn
