import React from 'react'
import { Bar } from 'react-chartjs-2'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'

const ChartVertical: React.FC<any> = (props: any) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  )

  const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 2
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as const,
        display: false
      },
      title: {
        display: false
      }
    }
  }
  const labels = [
    'Proposta em Aprovação',
    'Proposta enviada ao Cliente',
    'Proposta em Negociação',
    'Proposta Renovada',
    'Contato Ativo',
    'Contrato Creditado',
    'Declinado'
  ]
  const data = {
    labels,
    datasets: [
      {
        label: 'Qtde',
        data: labels.map((element, index) => props.indicators[index]),
        borderColor: 'rgb(7, 140, 27)',
        backgroundColor: 'rgba(7, 140, 27, 0.7);'
      }
    ]
  }
  return <Bar options={options} data={data} height='300px' width='500px'/>
}
export default ChartVertical
