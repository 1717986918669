import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import {
	Box,
	Div,
	InputContainer,
	CheckBoxContainer,
	CheckBoxWrapper,
} from '../../styles';
import { ButtonIcon } from '../../../CreateIndicators/styles';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { maskReais } from '../../../../utils/masks';
import { CancelButtonIcon } from './styles';

interface LeadFormProps {
	submit: boolean;
	lead: any;
	disable?: boolean;
	handleFormData: (responseFromForm?: any) => void;
}

const schemaLead = yup
	.object()
	.shape({
		benefits: yup
			.array()
			.of(
				yup.object().shape({
					benefit: yup.string().trim().required(),
					quantity: yup.string().trim().required(),
					price: yup.string().trim().required(),
				}),
			)
			.required(),
		nutrition_vr: yup.boolean().required(),
		is_pae: yup.boolean().required(),
		pharmacy_discount: yup.boolean().required(),
		total_pass: yup.boolean().required(),
		food_insurance: yup.boolean().required(),
		marketplace_or_loyalty_vr: yup.boolean().required(),
		rebate_or_value: yup.boolean().required(),
		payment_term: yup.boolean().required(),
	})
	.required();

const LeadForm: React.FC<LeadFormProps> = (props: LeadFormProps) => {
	const formElement = useRef<HTMLFormElement | null>(null);
	const [DATA] = useState<any[]>([
		{ text: 'VR Multi Alimentação', value: '11' },
		{ text: 'VR Multi Refeição', value: '12' },
		{ text: 'VR Multi Aux. VR + VA', value: '13' },
		{ text: 'VR Multi Aux. Home Office', value: '14' },
		{ text: 'VR Multi Mobilidade', value: '15' },
		{ text: 'VR Vale Transporte', value: '16' },
		{ text: 'VR Multi Aux. Alimentação', value: '17' },
		{ text: 'VR Multi Aux. Refeição', value: '18' },
		{ text: 'VR Gente - Pontomais', value: '19' },
	]);

	const {
		register,
		control,
		handleSubmit,
		setValue,
		formState: { isDirty },
	} = useForm({
		defaultValues: {
			benefits: [{ benefit: '2', quantity: '0', price: maskReais('0') }],
			nutrition_vr: props.lead.steps.find((step: any) => step.name === 'Lead')
				.data.nutrition_vr,
			is_pae: props.lead.steps.find((step: any) => step.name === 'Lead').data
				.is_pae,
			pharmacy_discount: props.lead.steps.find(
				(step: any) => step.name === 'Lead',
			).data.pharmacy_discount,
			total_pass: props.lead.steps.find((step: any) => step.name === 'Lead')
				.data.total_pass,
			food_insurance: props.lead.steps.find((step: any) => step.name === 'Lead')
				.data.food_insurance,
			marketplace_or_loyalty_vr: props.lead.steps.find(
				(step: any) => step.name === 'Lead',
			).data.marketplace_or_loyalty_vr,
			rebate_or_value: props.lead.steps.find(
				(step: any) => step.name === 'Lead',
			).data.rebate_or_value,
			payment_term: props.lead.steps.find((step: any) => step.name === 'Lead')
				.data.payment_term,
		},
		resolver: yupResolver(schemaLead),
	});

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'benefits',
	});

	const onSubmit = async (data: any, e: any): Promise<void> => {
		e.preventDefault();
		if (!(await schemaLead.isValid(data))) return;
		if (!isDirty) return;

		const leadData = {
			benefits: data?.benefits,
			nutrition_vr: JSON.parse(data?.nutrition_vr),
			is_pae: JSON.parse(data?.is_pae),
			pharmacy_discount: JSON.parse(data?.pharmacy_discount),
			total_pass: JSON.parse(data?.total_pass),
			food_insurance: JSON.parse(data?.food_insurance),
			marketplace_or_loyalty_vr: JSON.parse(data?.marketplace_or_loyalty_vr),
			rebate_or_value: JSON.parse(data?.rebate_or_value),
			payment_term: JSON.parse(data?.payment_term),
		};

		const steps = props.lead?.steps as any[];

		const payload = {
			step_sequence: '2',
			step_sequence_id: steps.find(el => el?.sequence === parseInt('2'))?.id,
			data: leadData,
		};

		props.handleFormData(payload);
	};

	const loadBenefits = (data: any[]): void => {
		if (data && data.length === 0) return;

		fields.forEach((el: any, index: number) => {
			remove(index);
		});

		if (!Array.isArray(data)) {
			append({ benefit: '2', quantity: '0', price: maskReais('0') });
			return;
		}

		data.forEach(item => {
			append({
				benefit: item.benefit,
				quantity: item.quantity,
				price: item.price,
			});
		});
	};

	const handleAddBenefits = (): void => {
		append({ benefit: '2', quantity: '0', price: maskReais('0') });
	};

	const handleRemoveBenefits = (index: number): void => {
		remove(index);
	};

	useEffect(() => {
		loadBenefits(
			props.lead.steps.find((step: any) => step.name === 'Lead').data.benefits,
		);
	}, []);

	useEffect(() => {
		if (props.submit && formElement.current)
			formElement.current.dispatchEvent(
				new Event('submit', { cancelable: true, bubbles: true }),
			);
	}, [props.submit]);

	return (
		<>
			<ButtonIcon
				type="button"
				onClick={() => handleAddBenefits()}
				disabled={props.disable}
			>
				<p>+ INCLUIR BENEFÍCIOS</p>
			</ButtonIcon>
			<Box>
				<form ref={formElement} onSubmit={handleSubmit(onSubmit)}>
					{fields.map((field, index) => (
						<Div key={field.id}>
							<InputContainer>
								<label>Benefícios</label>
								<select
									disabled={props.disable}
									{...register(`benefits.${index}.benefit`)}
									name={`benefits.${index}`}
									onChange={e =>
										setValue(`benefits.${index}.benefit`, e.target.value, {
											shouldDirty: true,
										})
									}
								>
									{DATA.map(
										(keyValue: { text: string; value: string }, index) => {
											return (
												<option value={keyValue?.value} key={index}>
													{keyValue?.text}
												</option>
											);
										},
									)}
								</select>
							</InputContainer>
							<InputContainer>
								<label>Quantidade</label>
								<input
									disabled={props.disable}
									{...register(`benefits.${index}.quantity`)}
									name={`quantity.${index}`}
									onChange={e =>
										setValue(`benefits.${index}.quantity`, e.target.value, {
											shouldDirty: true,
										})
									}
								/>
							</InputContainer>
							<InputContainer>
								<label>Valor</label>
								<div style={{ display: 'flex' }}>
									<input
										disabled={props.disable}
										{...register(`benefits.${index}.price`)}
										name={`price.${index}`}
										onChange={e =>
											setValue(
												`benefits.${index}.price`,
												maskReais(e.target.value),
												{ shouldDirty: true },
											)
										}
									/>
									<CancelButtonIcon
										disabled={props.disable}
										type="button"
										onClick={() => handleRemoveBenefits(index)}
									>
										<p>X</p>
									</CancelButtonIcon>
								</div>
							</InputContainer>
						</Div>
					))}
					<Div>
						<p>
							Atual fornecedor oferece algum benefício adicional? Taxa Zero?
							Desconto farmácia? Programa de assistência?
						</p>
					</Div>
					<CheckBoxWrapper>
						<CheckBoxContainer>
							<input
								disabled={props.disable}
								type="checkbox"
								{...register('nutrition_vr')}
								name="nutrition_vr"
								value={'true'}
							/>
							<label>VR Nutrição</label>
						</CheckBoxContainer>
						<CheckBoxContainer>
							<input
								disabled={props.disable}
								type="checkbox"
								{...register('is_pae')}
								name="is_pae"
								value={'true'}
							/>
							<label>PAE</label>
						</CheckBoxContainer>
						<CheckBoxContainer>
							<input
								disabled={props.disable}
								type="checkbox"
								{...register('pharmacy_discount')}
								name="pharmacy_discount"
								value={'true'}
							/>
							<label>Desconto Farmácia</label>
						</CheckBoxContainer>
						<CheckBoxContainer>
							<input
								disabled={props.disable}
								type="checkbox"
								{...register('total_pass')}
								name="total_pass"
								value={'true'}
							/>
							<label>Total Pass</label>
						</CheckBoxContainer>
						<CheckBoxContainer>
							<input
								disabled={props.disable}
								type="checkbox"
								{...register('food_insurance')}
								name="food_insurance"
								value={'true'}
							/>
							<label>Seguro Alimentação</label>
						</CheckBoxContainer>
						<CheckBoxContainer>
							<input
								disabled={props.disable}
								type="checkbox"
								{...register('marketplace_or_loyalty_vr')}
								name="marketplace_or_loyalty_vr"
								value={'true'}
							/>
							<label>VR Fidelidade</label>
						</CheckBoxContainer>
						<CheckBoxContainer>
							<input
								disabled={props.disable}
								type="checkbox"
								{...register('rebate_or_value')}
								name="rebate_or_value"
								value={'true'}
							/>
							<label>Rebate/Valor</label>
						</CheckBoxContainer>
						<CheckBoxContainer>
							<input
								disabled={props.disable}
								type="checkbox"
								{...register('payment_term')}
								name="payment_term"
								value={'true'}
							/>
							<label>Prazo de Pagamento</label>
						</CheckBoxContainer>
					</CheckBoxWrapper>
				</form>
			</Box>
		</>
	);
};

export default LeadForm;
